import React, { Fragment } from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { persistor, RootState, store } from './redux/store';
import Home from './screens/home/index';
import Links from './screens/links/links';
import Blog from './screens/blog/blog';
import NewsletterSubscription from './screens/emailSubscription/newsletterSubscription';
import GannChartJS from './screens/gannChart/GannChartJs';
import NeedToLoginBeforeAccessPage from './screens/gannChart/needToLoginBeforeAccessPage';
import NeedAuthorizationBeforeAccessPage from './screens/gannChart/needAuthorizationBeforeAccessPage';
import DmatPage from './screens/dMatAccount/dmatPage';
import Dashboard from './screens/dashboard/dashboard';
import BestDeliveryStocks from './screens/bestDeliveryStocks/bestDeliveryStocks';
import AddNewStockInfo from './screens/bestDeliveryStocks/addNewStockInfo';
import { PersistGate } from 'redux-persist/integration/react';
import UpdateStockInfo from './screens/bestDeliveryStocks/updateStockinfo';
import NeedToLoginBeforeAccessBestStockPage from './screens/bestDeliveryStocks/needToLoginBeforeAccessPage';
import NeedAccessForStockDeliveryPage from './screens/bestDeliveryStocks/needAccessForStockDeliveryPage';
import MasterClassSubscription from './screens/emailSubscription/masterClassSubscription';
import SubcsriptonThankyou from './screens/emailSubscription/thanyou';
import ResetPasswordPage from './screens/auth/resetPasswordPage';
import PasswordChangedMessage from './screens/auth/passwordChangedMessage';
import Profile from './screens/profile/profile';
import AddNewStockObservation from './screens/stockObservation/addNewStockObservation';
import UpdateStockObservation from './screens/stockObservation/updateStockObservation';
import StockObservation from './screens/stockObservation/stockObservation';
import NeedAccessForStockObservation from './screens/stockObservation/needAccessForStockObservation';
import NeedToLoginBeforeAccessStockObservation from './screens/stockObservation/needToLoginBeforeAccessStockObservation';
import MasterClass from './screens/masterClass/masterClass';
import NeedToLoginBeforeAccessMasterClass from './screens/masterClass/needToLoginBeforeAccessMasterClassPage';
import Payment from './screens/payment/payment';
import EditStockInfo from './screens/bestDeliveryStocks/editStockInfo';
import EditStockObservation from './screens/stockObservation/editStockObservation';
import UpdateCalculatorInput from './screens/gannChart/UpdateCalulatorInput';

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import GannChartJsMultipleDataSet from "./screens/gannChart/GannChartJsMultipleDataSet";
import GannChartIndUsa from "./screens/gannChart/GannChartIndUsa";
function App() {
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={true}
        isVerboseMode={false}
      >
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Routes />
            </PersistGate>
        </Provider>
      </CacheBuster>
    );
}
function Routes() {
    const user: any = useSelector<RootState>((state) => state.user.user);
    return (
        <Router>
            {user ? (
                <Fragment>
                    <Switch>
                        <Route
                            exact
                            path="/admin"
                            component={
                                user.role.includes('A')
                                    ? () => <Dashboard />
                                    : () => <Redirect to="/home" />
                            }
                        />
                        <Route
                            exact
                            path="/update-calculator-input"
                            component={
                                user.role.includes('A')
                                    ? () => <UpdateCalculatorInput />
                                    : () => <Redirect to="/home" />
                            }
                        />
                        <Route
                            exact
                            path="/addnewstockinfo"
                            component={
                                user.role.includes('A')
                                    ? () => <AddNewStockInfo />
                                    : () => <Redirect to="/home" />
                            }
                        />
                        <Route
                            exact
                            path="/edit-stock-info/:id"
                            component={
                                user.role.includes('A')
                                    ? (props: any) => (
                                          <EditStockInfo {...props} />
                                      )
                                    : () => <Redirect to="/home" />
                            }
                        />
                        <Route
                            exact
                            path="/edit-stock-observation/:id"
                            component={
                                user.role.includes('A')
                                    ? (props: any) => (
                                          <EditStockObservation {...props} />
                                      )
                                    : () => <Redirect to="/home" />
                            }
                        />
                        <Route
                            exact
                            path="/addnewstockobservation"
                            component={
                                user.role.includes('A')
                                    ? () => <AddNewStockObservation />
                                    : () => <Redirect to="/home" />
                            }
                        />
                        <Route
                            exact
                            path="/stockobservation"
                            component={
                                user.role.includes('A')
                                    ? () => <UpdateStockObservation />
                                    : user.role.includes('O')
                                    ? () => <StockObservation />
                                    : () => <NeedAccessForStockObservation />
                            }
                        />
                        <Route
                            exact
                            path="/trendtrading"
                            component={
                                user.role.includes('A') ||
                                user.role.includes('C')
                                    ? () => <GannChartJS />
                                    : () => (
                                          <NeedAuthorizationBeforeAccessPage />
                                      )
                            }
                        />
                        <Route
                            exact
                            path="/trend-trading"
                            component={
                                user.role.includes('A') ||
                                user.role.includes('C')
                                ? () => <GannChartIndUsa />
                                : () => (
                                <NeedAuthorizationBeforeAccessPage />
                                )
                            }
                        />
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/links" component={Links} />
                        <Route exact path="/blog" component={Blog} />
                        <Route
                            exact
                            path="/subscribe"
                            component={NewsletterSubscription}
                        />
                        <Route
                            exact
                            path="/master_class"
                            component={MasterClass}
                        />
                        <Route
                            exact
                            path="/master-class-subscription"
                            component={MasterClassSubscription}
                        />
                        <Route
                            exact
                            path="/opentradingaccount"
                            component={DmatPage}
                        />
                        <Route
                            exact
                            path="/password-changed-successfully"
                            component={PasswordChangedMessage}
                        />
                        <Route exact path="/pay" component={Payment} />
                        <Redirect from="/" to="/home" />
                    </Switch>
                </Fragment>
            ) : (
                <Fragment>
                    <Switch>
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/links" component={Links} />
                        <Route exact path="/blog" component={Blog} />
                        <Route
                            exact
                            path="/trendtrading"
                            component={NeedToLoginBeforeAccessPage}
                        />
                        <Route
                            exact
                            path="/master_class"
                            component={NeedToLoginBeforeAccessMasterClass}
                        />
                        <Route
                            exact
                            path="/stockobservation"
                            component={NeedToLoginBeforeAccessStockObservation}
                        />
                        <Route
                            exact
                            path="/subscribe"
                            component={NewsletterSubscription}
                        />
                        <Route
                            exact
                            path="/opentradingaccount"
                            component={DmatPage}
                        />
                        <Route
                            exact
                            path="/master-class-subscription"
                            component={MasterClassSubscription}
                        />
                        <Route
                            exact
                            path="/subscription-thankyou"
                            component={SubcsriptonThankyou}
                        />
                        <Route
                            exact
                            path="/reset_user_password/:token"
                            component={ResetPasswordPage}
                        />
                        <Route
                            exact
                            path="/password-changed-successfully"
                            component={PasswordChangedMessage}
                        />
                        <Redirect from="/" to="/home" />
                    </Switch>
                </Fragment>
            )}
        </Router>
    );
}
export default App;
