import React from 'react';

const baseLocation = '/assets/images/';

type IImage = {
    alt: string;
    src: string;
    className?: string;
    width?: string;
    height?: string;
    type?: string;
};

export const Img = ({
    alt,
    src,
    className = '',
    width = 'auto',
    height = 'auto',
}: IImage) => {
    return (
        <img
            className={className}
            width={width}
            height={height}
            alt={alt}
            src={baseLocation + src}
        />
    );
};
