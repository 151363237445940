import React, { Fragment } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { NewsLetterSubscriptionForm } from '../../services/subscription.service';
import * as subscription from '../../services/subscription.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';

function NewsletterSubscription() {
    const { control, handleSubmit, register } = useForm();
    const history = useHistory();
    const onSubmit = (data: any) => {
        const inputForm: NewsLetterSubscriptionForm = {
            name: data.name,
            email: data.email.toLowerCase(),
            number: data.number,
            state: data.state,
        };
        subscription.NewsLetterSubscription(inputForm, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    history.push('/subscription-thankyou');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    return (
        <Fragment>
            <Header />
            <PageContainer>
                <Title>Join the Stock Market Today Community</Title>
                <MainBlock>
                    <RigthPart>
                        <img
                            style={{ width: '200px' }}
                            src={require('../../images/email/emailPageImage.png')}
                            alt={'Finedu consultancy'}
                        />
                    </RigthPart>
                    <LeftPart>
                        <Form>
                            <Controller
                                as={StyledFormControl}
                                name="name"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="Name"
                                ref={register({ required: true })}
                            />
                            <Controller
                                as={StyledFormControl}
                                name="number"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="Mobile number"
                                ref={register({ required: true })}
                            />
                            <Controller
                                as={StyledFormControl}
                                name="email"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="Email"
                                ref={register({ required: true })}
                            />

                            <Controller
                                as={StyledFormControl}
                                name="state"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="State"
                                ref={register({ required: true })}
                            />
                        </Form>
                        <ButtonBox onClick={handleSubmit(onSubmit)}>
                            Register Now
                        </ButtonBox>
                    </LeftPart>
                </MainBlock>
            </PageContainer>
            <Footer />
        </Fragment>
    );
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 100px 50px;
    @media (max-width: 575.98px) {
        margin: 20px;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const StyledFormControl = styled(Form.Control)`
    background: #f9f8fb;
    height: 40px;
    width: 25vw;
    font-size: 18px;
    margin-top: 15px;
    padding: 0 10px 0 10px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
    @media (max-width: 767.98px) {
        width: 40vw;
    }
`;
const ButtonBox = styled.div`
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    bottom: 50px;
    width: 25vw;
    height: 40px;
    border-radius: 5px;
    color: white;
    background: black;
    margin: 20px 0px 10px;
    outline: none;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 5px;
    @media (max-width: 767.98px) {
        width: 40vw;
    }
`;
const LeftPart = styled.div`
    padding: 50px 100px 0 0;
`;
const RigthPart = styled.div``;
const Title = styled.div`
    font-size: 30px;
    font-weight: bold;
`;
const MainBlock = styled.div`
    display: flex;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
export default NewsletterSubscription;
