import React, { Fragment } from 'react';
import { VictoryLabel, VictoryPie } from 'victory';
import styled from 'styled-components';

function SellChartDonutVictory(props) {
    let values = props.s;
    let data = [
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 1 },
    ];
    let dataRadius = [140, 140, 140, 140, 140, 140, 140, 140, 140, 140];
    let prefix = [
        'Sell@',
        'T1',
        'T2',
        'T3',
        'T4',
        'T5',
        'T6',
        'T7',
        'T8',
        'T9',
    ];
    return (
        <Fragment>
            <UpperLayer></UpperLayer>
            <VictoryPie
                data={data}
                // data={[{ x: 1, y: 1 }]}
                labelRadius={({ index }) => dataRadius[index]}
                radius={190}
                innerRadius={100}
                labels={({ index }) => [prefix[index], values[index]]}
                colorScale={[
                    '#9D0404',
                    '#EC4949',
                    '#EC4949',
                    '#e4210d',
                    '#EC4949',
                    '#EC4949',
                    '#e4210d',
                    '#EC4949',
                    '#EC4949',
                    '#e4210d',
                ]}
                padAngle={1}
                style={{
                    labels: { fill: 'white', fontSize: 18, fontWeight: 'bold' },
                    data: {
                        fillOpacity: 0.9,
                        stroke: '#9D0404',
                        strokeWidth: 1,
                    },
                }}
                labelComponent={<VictoryLabel textAnchor="middle" />}
            />
        </Fragment>
    );
}
const UpperLayer = styled.div`
    position: absolute;
    @media (max-width: 575.98px) {
        width: 100%;
        height: 400px;
        z-index: 1;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
export default SellChartDonutVictory;
