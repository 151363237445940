import React from 'react';
import Header from '../../components/Header/Header';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { Img } from "../../components/common/Img";

function MasterClass() {
    const history = useHistory();
    return (
        <div>
            <Header />
            <DataBox>
                <Button onClick={() => history.push('/pay')}>
                    Subscribe to Master Class
                </Button>
                <CenterContaiener>
                  <Img src='masterClass/class.jpg' alt='Master class StoxPathshala' width='100%'/>
                </CenterContaiener>
            </DataBox>
        </div>
    );
}
const DataBox = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 100%;
        margin-left: 20px;
    }
    @media (min-width: 576px) {
        margin: 50px 0 0 0;
        padding: 0 0 0 50px;
    }
`;
const CenterContaiener = styled.div`
    display: flex;
    margin-top: 20px;
`;
export default MasterClass;
