import React, { Fragment } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { notify } from 'react-notify-toast';
import ModalChangePassword from '../auth/changePassword';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

function Profile() {
    const [changePassword, showModalChangePassword] = React.useState(false);
    const user: any = useSelector<RootState>((state) => state.user.user);
    return (
        <Fragment>
            <ModalChangePassword
                show={changePassword}
                onHide={() => showModalChangePassword(false)}
                onSuccess={() =>
                    notify.show('Password changed', 'success', 3000)
                }
            />
            <Header />
            <ProfileSection>
                <Button onClick={() => showModalChangePassword(true)}>
                    {' '}
                    Change Password
                </Button>
                <ProfileSummary>
                    <div>Name : {user.name}</div>
                    <div>Email : {user.email}</div>
                    <div>Phone : {user.mobile}</div>
                </ProfileSummary>
            </ProfileSection>
            <Footer />
        </Fragment>
    );
}
const ProfileSection = styled.div`
    padding: 30px;
`;
const ProfileSummary = styled.div`
    margin: 50px 0 50px 0;
`;
export default Profile;
