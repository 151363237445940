import { Request } from './common.service';
import * as config from '../config';

export interface OrderInput {
    amount: number;
    products: string;
}
export interface PaymentConfirmationForm {
    order_id: string;
    payment_id: string;
    signature: string;
}
export interface PaymentFailedForm {
    order_id: string;
    payment_id: string;
    error: string;
}

export async function GetOrderId(
    form: OrderInput,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlRazorpayOrderid,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function UpdatePaymentStatus(
    form: PaymentConfirmationForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlRazorpayUpdatePaymentStatus,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function UpdatePaymentFailureStatus(
    form: PaymentFailedForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlRazorpayUpdateFailedPaymentStatus,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
