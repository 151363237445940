import { store } from '../redux/store';
import { logout as logoutAction } from '../redux/slices/user.slice';
import userI from '../interfaces/app/userI';
import StockInfoI from '../interfaces/app/stockinfoI';
import {
    addStockInfo as addStockInfoAction,
    logoutstockinfo,
} from '../redux/slices/stockinfo.slice';
import {
    addStockObservation as addStockObservationAction,
    logoutstockobservation,
} from '../redux/slices/stockobservation.slice';
import StockObservationI from '../interfaces/app/stockobservationI';

export function addStockInfo(stockinfo: StockInfoI) {
    store.dispatch(addStockInfoAction(stockinfo));
    return stockinfo;
}

export function addStockObservation(stockObservation: StockObservationI) {
    store.dispatch(addStockObservationAction(stockObservation));
    return stockObservation;
}

export function logout(user: userI) {
    store.dispatch(logoutAction(user));
    store.dispatch(logoutstockinfo());
    store.dispatch(logoutstockobservation());
}
export function emptyStockInfoList() {
    store.dispatch(logoutstockinfo());
    store.dispatch(logoutstockobservation());
}
