import React, { Fragment } from 'react';
import styled from 'styled-components';
import { selectTheme } from '../../constants/theme.constant';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function Footer() {
    const history = useHistory();
    return (
        <Fragment>
            <FooterContainer>
                <IntroContainer>
                    <Title>ABOUT StoxPathshala</Title>
                    StoxPathshala is a team of highly enthusiastic and efficient
                    professionals, headed by Mr. Vipul Kaushikk who is dedicated
                    to spread Financial Awareness across country.
                </IntroContainer>
                <ProductInfoContainer>
                    <Title>SERVICES</Title>
                    <ProductLink>Trend Trading Calculator</ProductLink>
                    <ProductLink>Best Stock Delivery</ProductLink>
                    {/*<ProductLink>Stock Observation Service</ProductLink>*/}
                    <ProductLink>Master Class</ProductLink>
                </ProductInfoContainer>
                <ContactInfoContainer>
                    <Title>Contact us</Title>
                    <ProductLink>Mobile +91 7827286174</ProductLink>
                    <ProductLink>trendtradingcalculator@gmail.com</ProductLink>
                </ContactInfoContainer>
                <ContactusContainer>
                    <Title>STAY IN TOUCH.</Title>
                    <Button onClick={() => history.push('/subscribe')}>
                        Subscribe our newsletter
                    </Button>
                    <ProductLink>Privacy Policy</ProductLink>
                    <ProductLink>Copyright © 2022 - StoxPathshala</ProductLink>
                </ContactusContainer>
            </FooterContainer>
        </Fragment>
    );
}
const getConstants = selectTheme();
const FooterContainer = styled.div`
    display: flex;
    background: ${getConstants.t3Four};
    color: ${getConstants.t3Two};
    padding-top: 30px;
    @media (max-width: 575.98px) {
        flex-direction: column;
        padding-left: 20px;
    }
    @media (min-width: 576px) {
        height: 40vh;
        justify-content: space-evenly;
    }
`;
const ContactusContainer = styled.div`
    @media (max-width: 575.98px) {
        width: 90%;
    }
`;
const ContactInfoContainer = styled.div`
    width: 22vw;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 90%;
    }
`;
const ProductInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 90%;
    }
`;
const IntroContainer = styled.div`
    width: 22vw;
    margin-left: 2vw;
    @media (max-width: 575.98px) {
        width: 90%;
        margin-left: 0px;
    }
`;
const ProductLink = styled.div`
    margin-top: 10px;
    @media (max-width: 575.98px) {
        margin-top: 5px;
    }
`;
const Title = styled.div`
    font-weight:bold;
    font-size:26px;
    color:white;
    margin-top:10px;
`;
export default Footer;
