import { Request } from './common.service';
import * as config from '../config';

export interface StockOservationInput {
    uuid: string;
    title: string;
    content: string;
}
export interface UpdateStockObservation {
    id: number;
    title: string;
    content: string;
}
export interface StockOservationId {
    id: number;
}
export async function AddNewStockObservation(
    form: StockOservationInput,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAddNewStockObservation,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function UpdateStockObservation(
    form: UpdateStockObservation,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlUpdateStockObservation,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function SyncStockObservation(
    loader?: Function,
    toast?: Function
) {
    const form = '';
    return await Request({
        url: config.urlSyncStockObservation,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function GetStockObservationById(
    form: StockOservationId,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlGetStockObservationById,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function DeleteStockObservation(
    form: StockOservationId,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlDeleteStockObservation,
        method: 'DELETE',
        form: form,
        loader: loader,
        toast: toast,
    });
}
