import { Request } from './common.service';
import * as config from '../config';

export interface StockInfoInput {
    uuid: string;
    title: string;
    content: string;
}
export interface UpdateStockInfo {
    id: number;
    title: string;
    content: string;
}
export interface StockInfoId {
    id: number;
}

export async function AddNewStock(
    form: StockInfoInput,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAddNewStockInfo,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function UpdateStockInfo(
    form: UpdateStockInfo,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlUpdateStockInfo,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function SyncStockInfo(loader?: Function, toast?: Function) {
    const form = '';
    return await Request({
        url: config.urlSyncStockInfo,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function GetStockInfoById(
    form: StockInfoId,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlGetStockInfoById,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function DeleteStockInfo(
    form: StockInfoId,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlDeleteStockInfo,
        method: 'DELETE',
        form: form,
        loader: loader,
        toast: toast,
    });
}
