import React, { Fragment, useEffect } from 'react';
import Header from '../../components/Header/Header';
import styled from 'styled-components';
import Footer from '../../components/Footer/Footer';
import StockInfo from './stockInfo';
import { useHistory } from 'react-router-dom';
import * as stockobservation from '../../services/stockobservation.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import {
    addStockObservation,
    emptyStockInfoList,
} from '../../helpers/query.helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

function UpdateStockObservation() {
    const history = useHistory();
    const stockobservationlist: any = useSelector<RootState>(
        (state) => state.stockobservation
    );
    function syncDownWithSignIn() {
        stockobservation
            .SyncStockObservation()
            .then((res) => {
                if (res.status === STATUS_REQUEST_SUCCESS) {
                    emptyStockInfoList();
                    if (res.response_status) {
                        const stockobservationlist =
                            res.response['stockobservationlist'];
                        stockobservationlist.map((c: any) =>
                            addStockObservation(c)
                        );
                    } else {
                        for (let i = 0; i < res.error.length; i++) {
                            notify.show(res.error[i]['msg'], 'error', 3000);
                        }
                    }
                } else {
                    console.log('error in syncdown');
                }
            })
            .catch((err) => {
                console.log('error in syncdown' + err);
            });
    }
    useEffect(() => {
        syncDownWithSignIn();
    }, []);
    return (
        <Fragment>
            <Header />

            <Title>Stock Observation Service.</Title>
            <ButtonBox onClick={() => history.push('/addnewstockobservation')}>
                Add New Stock Observation
            </ButtonBox>
            <div>
                {stockobservationlist.map(function (c: any) {
                    return (
                        <StockInfo
                            key={c.id}
                            id={c.id}
                            title={c.title}
                            content={c.content}
                            time={c.updated}
                            refresh={syncDownWithSignIn}
                        />
                    );
                })}
            </div>
            <Footer />
        </Fragment>
    );
}
const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const ButtonBox = styled.div`
    text-align: center;
    align-self: center;
    cursor: pointer;
    width: 240px;
    height: 40px;
    border-radius: 6px;
    color: white;
    background: #273746;
    margin: 10px 50px 10px 10px;
    padding: 10px 0 10px 0;
`;
export default UpdateStockObservation;
