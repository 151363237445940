import { createLogger } from 'redux-logger';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as config from '../config';
import { StateType, ActionType } from 'typesafe-actions';

/* reducers */
import appReducer from './slices/app.slice';
import userReducer from './slices/user.slice';
import tabReducer from './slices/tab.slice';
import stockinfoReducer from './slices/stockinfo.slice';
import stockobservationReducer from './slices/stockobservation.slice';

const persistConfig = {
    key: 'finedu',
    storage: storage,
    version: 2,
    stateReconciler: autoMergeLevel2,
    whitelist: ['user', 'tab', 'stockinfo', 'stockobservation'],
    debug: true,
};

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    tab: tabReducer,
    stockinfo: stockinfoReducer,
    stockobservation: stockobservationReducer,
});
// @ts-ignore
const persistCombinedReducers = persistReducer(persistConfig, rootReducer);
const middlewares = [];

if (config.deployment === 'development') {
    // console.log('redux logger added');
    middlewares.push(createLogger({ collapsed: true }));
}
const store = configureStore({
    reducer: persistCombinedReducers,
    middleware: middlewares,
    devTools: config.deployment !== 'production',
});
const persistor = persistStore(store, null, () => {
    // if you want to get restoredState
    // console.log("restoredState", store.getState());
});

store.subscribe(function () {
    (async () => {
        // console.log(store.getState())
    })().catch(function (e) {
        console.log(e);
        throw e;
    });
});

export type RootAction = ActionType<typeof rootReducer>;
export type RootState = StateType<ReturnType<typeof rootReducer>>;
export type AppDispatch = typeof store.dispatch;
export { store, persistor };
