export const selectTheme = function () {
    let theme = '1';
    if (theme === '1') {
        return {
            t3One: '#5c2d8b',
            t3Two: '#E7E7E7',
            t3Three: '#1a062e',
            t3Four: '#4F4F4F',

            t2One: '#00c4cc',
            t2Two: '#f6fdfe',
            t2Three: '#00636e',
            t2Four: '#008b95',

            t1One: '#ffb829',
            t1Two: '#fffeca',
            t1Three: '#855203',
            t1Four: '#cb8c1a',

            t4One: '#E5E8E8',
            t4Two: '#fffeca',
            t4Three: '#855203',
            t4Four: '#cb8c1a',

            fontColorT1: '#262022',
            font: 'Poppins, sans-serif',
        };
    } else {
        return {
            headerBG: '#5c2d8b',
            footerBG: '#4F4F4F',
            headerFontColor: '#1a062e',
            footerFontColor: '#E7E7E7',
            optionSix: '#f9f8fb',
            optionSeven: '#ffffff',
            t1One: '#ffb829',
            t1Two: '#fffeca',
            t1Three: '#855203',
            t1Four: '#cb8c1a',
            bgFive: '#008b95',
            fontColorOne: '#F4F6F6',
            font: 'Poppins, sans-serif',
        };
    }
};
