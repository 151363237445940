import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import styled from 'styled-components';
import * as payment from '../../services/payment.service';
import {
    OrderInput,
    PaymentConfirmationForm,
    PaymentFailedForm,
} from '../../services/payment.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import Button from 'react-bootstrap/Button';
import Modal from './qr_code';

function loadScript(src: string) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        document.body.appendChild(script);
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
    });
}
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));
function Payment() {
    const classes = useStyles();
    const [modalShow, setModalShow] = React.useState(false);
    const [ttcValue, setTtcValue] = React.useState('');
    const [ttcAmount, setTtcAmount] = useState(0);
    const [ttcDuration, setTtcDuration] = useState('');
    const [sdsValue, setSdsValue] = React.useState('');
    const [sdsAmount, setSdsAmount] = React.useState(0);
    const [sdsDuration, setSdsDuration] = React.useState('');
    const [sosValue, setSosValue] = React.useState('');
    const [sosAmount, setSosAmount] = React.useState(0);
    const [sosDuration, setSosDuartion] = React.useState('');
    const [mcValue, setMcValue] = React.useState('');
    const [mcAmount, setMcAmount] = React.useState(0);
    const [mcDuration, setMcDuration] = React.useState('');
    const [error, setError] = React.useState(false);
    const [selected, setSelected] = useState('');

    async function displayRazorpay() {
        if(ttcAmount + sdsAmount + sosAmount + mcAmount === 0){
            notify.show('Please select a product', 'warning', 3000);
            return
        }
        const res = await loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
        );

        if (!res) {
            alert('Some error occurred while processing the payment');
            return;
        }
        let data: OrderInput = {
            amount: 100,
            products: selected,
        };
        let orderId = '';
        payment.GetOrderId(data).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    orderId = res.response.order.id;
                    const options = {
                        // "key": "rzp_test_UDxrFCor5QuzRm",
                        key: 'rzp_live_DlQ0WqxMRNH905',
                        amount: res.response['order'].amount,
                        currency: 'INR',
                        name: 'FinEdu Consultancy',
                        description: 'Trading consultant',
                        // "image": "/static/media/finedu.c95f677b.png",
                        order_id: orderId,
                        handler: function (response: any) {
                            let confirmData: PaymentConfirmationForm = {
                                order_id: response.razorpay_order_id,
                                payment_id: response.razorpay_payment_id,
                                signature: response.razorpay_signature,
                            };
                            payment
                                .UpdatePaymentStatus(confirmData)
                                .then((res) => {});
                            // alert(response.razorpay_payment_id);
                            // alert(response.razorpay_order_id);
                            // alert(response.razorpay_signature)
                        },
                        prefill: {
                            name: res.response['user'].name,
                            email: res.response['user'].email,
                            contact: res.response['user'].contact,
                        },
                        notes: {
                            address: 'Finedu Consultancy',
                        },
                        theme: {
                            color: '#3399cc',
                        },
                    };
                    const _window = window as any;
                    const paymentObject = new _window.Razorpay(options);
                    paymentObject.on(
                        'payment.failed',
                        function (response: any) {
                            let failureData: PaymentFailedForm = {
                                order_id: response.razorpay_order_id,
                                payment_id: response.razorpay_payment_id,
                                error:
                                    response.error.code +
                                    '/' +
                                    response.error.description +
                                    '/' +
                                    response.error.reason +
                                    '/' +
                                    response.error.source +
                                    '/' +
                                    response.error.step,
                            };
                            payment
                                .UpdatePaymentFailureStatus(failureData)
                                .then((res) => {});
                            // alert(response.error.code);
                            // alert(response.error.description);
                            // alert(response.error.source);
                            // alert(response.error.step);
                            // alert(response.error.reason);
                            // alert(response.error.metadata.order_id);
                            // alert(response.error.metadata.payment_id);
                        }
                    );
                    paymentObject.open();
                } else {
                    notify.show('Try again!', 'error', 3000);
                }
            } else {
                // setLoading(false);
                notify.show('Oops, try again!!!', 'error', 3000);
            }
        });
    }
    const onSubmit = (e: any) => {
        e.preventDefault();
    };
    const handleRadioChangeTTC = (event: any) => {
        setTtcValue(event.target.value);
        setError(false);
    };
    const handleRadioChangeSDS = (event: any) => {
        setSdsValue(event.target.value);
        setError(false);
    };
    const handleRadioChangeSOS = (event: any) => {
        setSosValue(event.target.value);
        setError(false);
    };
    const handleRadioChangeMC = (event: any) => {
        setMcValue(event.target.value);
        setError(false);
    };
    const services = () => {
        let str = '';
        if (ttcValue !== '') {
            str = ttcValue;
        }
        if (sdsValue !== '') {
            if (str === '') {
                str = sdsValue;
            } else {
                str = str + '-' + sdsValue;
            }
        }
        if (sosValue !== '') {
            if (str === '') {
                str = sosValue;
            } else {
                str = str + '-' + sosValue;
            }
        }
        if (mcValue !== '') {
            if (str === '') {
                str = mcValue;
            } else {
                str = str + '-' + mcValue;
            }
        }
        setSelected(str);
    };
    useEffect(() => {
        services();
        if (ttcValue === 'ttc1month') {
            setTtcAmount(5900);
            setTtcDuration('1 month');
        } else if (ttcValue === 'ttc6month') {
            setTtcAmount(6000);
            setTtcDuration('6 month');
        } else if (ttcValue === 'ttc1year') {
            setTtcAmount(17700);
            setTtcDuration('1 year');
        } else {
            setTtcAmount(0);
            setTtcDuration('');
        }
        //setting sds amount
        if (sdsValue === 'sds1month') {
            setSdsAmount(5900);
            setSdsDuration('1 month');
        } else if (sdsValue === 'sds6month') {
            setSdsAmount(6000);
            setSdsDuration('6 month');
        } else if (sdsValue === 'sds1year') {
            setSdsAmount(17700);
            setSdsDuration('1 year');
        } else {
            setSdsAmount(0);
            setSdsDuration('');
        }
        //setting sos amount
        if (sosValue === 'sos1month') {
            setSosAmount(3540);
            setSosDuartion('1 month');
        } else {
            setSosAmount(0);
            setSosDuartion('');
        }
        //setting master class amount
        if (mcValue === 'mcnewstudent') {
            setMcAmount(17700);
            setMcDuration('New student');
        } else if (mcValue === 'mcoldstudent') {
            setMcAmount(9600);
            setMcDuration('Old student');
        } else {
            setMcAmount(0);
            setMcDuration('');
        }
    }, [ttcValue, ttcAmount, sdsValue, sosValue, mcValue]);
    return (
        <Fragment>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSuccess={() => notify.show('Logged in!', 'success', 3000)}
            />
            <Header />
            <PaymentRow>
                <PaymentBlock>
                    <FormControl
                        component="fieldset"
                        error={error}
                        className={classes.formControl}
                    >
                        <FormLabel component="legend">
                            Trend Trading Calculator
                        </FormLabel>
                        <RadioGroup
                            aria-label="quiz"
                            name="quiz"
                            value={ttcValue}
                            onChange={handleRadioChangeTTC}
                        >
                            <FormControlLabel
                                value="ttc1month"
                                control={<Radio />}
                                label="1 month @ 5,900 INR"
                            />
                            {/*<FormControlLabel value="ttc6month" control={<Radio />} label="6 month @ 6,000 INR" />*/}
                            <FormControlLabel
                                value="ttc1year"
                                control={<Radio />}
                                label="1 year @ 17,700 INR"
                            />
                            <FormControlLabel
                                value=""
                                control={<Radio />}
                                label="None"
                            />
                        </RadioGroup>
                    </FormControl>
                </PaymentBlock>
                {/*<PaymentBlock>*/}
                   {/* <FormControl*/}
                 {/*       component="fieldset"*/}
                 {/*       error={error}*/}
                    {/*    className={classes.formControl}*/}
                 {/*   >*/}
                   {/*     <FormLabel component="legend">*/}
                   {/*         Stock Delivery Service*/}
                   {/*     </FormLabel>*/}
                   {/*     <RadioGroup*/}
                   {/*         aria-label="quiz"*/}
                    {/*        name="quiz"*/}
                    {/*        value={sdsValue}*/}
                    {/*        onChange={handleRadioChangeSDS}*/}
                    {/*    >*/}
                    {/*        <FormControlLabel*/}
                     {/*           value="sds1month"*/}
                     {/*           control={<Radio />}*/}
                     {/*           label="1 month @ 5,900 INR"*/}
                     {/*       />*/}
                     {/*<FormControlLabel value="sds6month" control={<Radio />} label="6 month @ 6,000 INR" />*/}
                      {/*      <FormControlLabel*/}
                      {/*          value="sds1year"*/}
                      {/*          control={<Radio />}*/}
                      {/*          label="1 year @ 17,700 INR"*/}
                       {/*     />*/}
                        {/*    <FormControlLabel*/}
                        {/*        value=""*/}
                       {/*         control={<Radio />}*/}
                        {/*        label="None"*/}
                       {/*     />*/}
                      {/*  </RadioGroup>*/}
                    {/*</FormControl>*/}
              {/*  </PaymentBlock>*/}
                {/*<PaymentBlock>*/}
                {/*    <FormControl*/}
                {/*        component="fieldset"*/}
                {/*        error={error}*/}
                {/*        className={classes.formControl}*/}
                {/*    >*/}
                {/*        <FormLabel component="legend">*/}
                {/*            Stock Observation Service*/}
                {/*        </FormLabel>*/}
                {/*        <RadioGroup*/}
                {/*            aria-label="quiz"*/}
                {/*            name="quiz"*/}
                {/*            value={sosValue}*/}
                {/*            onChange={handleRadioChangeSOS}*/}
                {/*        >*/}
                {/*            <FormControlLabel*/}
                {/*                value="sos1month"*/}
                {/*                control={<Radio />}*/}
                {/*                label="1 month @ 3,540 INR"*/}
                {/*            />*/}
                {/*            <FormControlLabel*/}
                {/*                value=""*/}
                {/*                control={<Radio />}*/}
                {/*                label="None"*/}
                {/*            />*/}
                {/*        </RadioGroup>*/}
                {/*    </FormControl>*/}
                {/*</PaymentBlock>*/}
                <PaymentBlock>
                    <FormControl
                        component="fieldset"
                        error={error}
                        className={classes.formControl}
                    >
                        <FormLabel component="legend">Master Class</FormLabel>
                        <RadioGroup
                            aria-label="quiz"
                            name="quiz"
                            value={mcValue}
                            onChange={handleRadioChangeMC}
                        >
                            <FormControlLabel
                                value="mcnewstudent"
                                control={<Radio />}
                                label="For new students @ 17,700 INR"
                            />
                            <FormControlLabel
                                value="mcoldstudent"
                                control={<Radio />}
                                label="For old students @ 9,600 INR"
                            />
                            <FormControlLabel
                                value=""
                                control={<Radio />}
                                label="None"
                            />
                        </RadioGroup>
                    </FormControl>
                </PaymentBlock>
            </PaymentRow>

            <SubmitPaymentBox>
                <SummeryBox>
                    <SummaryTitle>Summary</SummaryTitle>
                    <SummaryRow>
                        <CourseSummary>
                            <CourseInfo>Trend Trading Calculator</CourseInfo>
                            <CourseAmount>₹ {ttcAmount}</CourseAmount>
                        </CourseSummary>
                        <CourseDuration>
                            {ttcDuration && ttcDuration}
                        </CourseDuration>
                    </SummaryRow>
                    {/*<SummaryRow>*/}
                     {/*   <CourseSummary>*/}
                      {/*      <CourseInfo>Stock Delivery Service</CourseInfo>*/}
                      {/*      <CourseAmount>₹ {sdsAmount}</CourseAmount>*/}
                      {/*  </CourseSummary>*/}
                      {/*  <CourseDuration>*/}
                      {/*      {sdsDuration && sdsDuration}*/}
                      {/*  </CourseDuration>*/}
                    {/*</SummaryRow>*/}
                    {/*<SummaryRow>*/}
                    {/*    <CourseSummary>*/}
                    {/*        <CourseInfo>Stock Observation Service</CourseInfo>*/}
                    {/*        <CourseAmount>₹ {sosAmount}</CourseAmount>*/}
                    {/*    </CourseSummary>*/}
                    {/*    <CourseDuration>*/}
                    {/*        {sosDuration && sosDuration}*/}
                    {/*    </CourseDuration>*/}
                    {/*</SummaryRow>*/}
                    <SummaryRow>
                        <CourseSummary>
                            <CourseInfo>Master Class</CourseInfo>
                            <CourseAmount>₹ {mcAmount}</CourseAmount>
                        </CourseSummary>
                        <CourseDuration>
                            {mcDuration && mcDuration}
                        </CourseDuration>
                    </SummaryRow>
                    <TotalAmountBox>
                        Total = ₹ {ttcAmount + sdsAmount + sosAmount + mcAmount}
                    </TotalAmountBox>
                </SummeryBox>
                <PaymentButtonBox>
                    <Button variant="outline-primary" onClick={displayRazorpay}>
                        Proceed to pay by Card/Net Banking/ Wallets
                    </Button>
                    or
                    <Button
                        variant="outline-primary"
                        onClick={() => setModalShow(true)}
                    >
                        Pay by PayTM/ Google Pay/ any QR Code Scan
                    </Button>
                </PaymentButtonBox>
            </SubmitPaymentBox>

            <Footer />
        </Fragment>
    );
}
const PaymentBlock = styled.div`
    padding: 10px;
    min-width: 250px;
`;
const SubmitPaymentBox = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const SummeryBox = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 40%;
    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
        width: 70%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        width: 70%;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
        flex-direction: column;
        align-items: center;
        width: 70%;
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const TotalAmountBox = styled.div`
    border-top: solid 2px grey;
    border-bottom: solid 2px grey;
    width: 50%;
    margin: 10px;
    padding: 10px;
    font-weight: bold;
`;
const SummaryTitle = styled.div`
    font-weight: bold;
    font-size: 30px;
`;
const SummaryRow = styled.div`
    font-size:18px;
    display:flex;
    flex-direction:column;
    width:100%;
    padding: 10px 0 10px 0;
`;
const CourseInfo = styled.div`
    font-size: 18px;
    width: 80%;
`;
const CourseSummary = styled.div`
    display: flex;
    width: 100%;
`;
const CourseDuration = styled.div`
    font-size: 12px;
`;
const CourseAmount = styled.div`
    font-size:18px;
    font-weight:bold;
`;
const PaymentRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
const PaymentButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;
export default Payment;
