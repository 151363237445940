import Axios from 'axios';
import micromatch from 'micromatch';
import { apiUrl } from '../config';
import { WR_TOAST_ERROR } from '../constants/core.constant';

export interface JSONRequest {
    url: string;
    method: any;
    form: any;
    loader?: Function;
    toast?: Function;
}

export interface Response {
    status: string;
    http_status: number;
    response_status: boolean;
    response: any;
    error: any;
}

export const STATUS_REQUEST_SUCCESS = 'STATUS_REQUEST_SUCCESS';
export const STATUS_REQUEST_ERROR = 'STATUS_REQUEST_ERROR';
export const STATUS_REQUEST_NO_INTERNET = 'STATUS_NO_INTERNET';

export function Request(req: JSONRequest): Promise<Response> {
    return new Promise((resolve, reject) => {
        if (req.loader) {
            req.loader(true);
        }
        Axios({
            method: req.method,
            url: apiUrl + req.url,
            data: req.form,
            withCredentials: true,
        })
            .then(function (response) {
                if (req.loader) {
                    req.loader(false);
                }
                return resolve({
                    status: STATUS_REQUEST_SUCCESS,
                    http_status: response.status,
                    response_status: response.data.status,
                    response: response.data.response,
                    error: response.data.errors || [],
                });
            })
            .catch(function (error) {
                if (req.loader) {
                    req.loader(false);
                }
                if (!error.response) {
                    if (req.toast) {
                        req.toast(WR_TOAST_ERROR, error.message);
                    }
                    return resolve({
                        status: STATUS_REQUEST_ERROR,
                        http_status: 0,
                        response_status: false,
                        response: null,
                        error: error.message,
                    });
                } else if (
                    micromatch.isMatch(error.response.status.toString(), '40*')
                ) {
                    if (req.toast) {
                        req.toast(WR_TOAST_ERROR, error.response.data);
                    }
                    return resolve({
                        status: STATUS_REQUEST_ERROR,
                        http_status: error.response.status,
                        response_status: false,
                        response: null,
                        error: error.response.data,
                    });
                } else if (
                    micromatch.isMatch(error.response.status.toString(), '50*')
                ) {
                    if (req.toast) {
                        req.toast(WR_TOAST_ERROR, error.response.data);
                    }
                    return resolve({
                        status: STATUS_REQUEST_ERROR,
                        http_status: error.response.status,
                        response_status: false,
                        response: null,
                        error: error.response.data,
                    });
                } else if (
                    micromatch.isMatch(error.response.status.toString(), '60*')
                ) {
                    if (req.toast) {
                        req.toast(
                            WR_TOAST_ERROR,
                            'You are not connected to internet'
                        );
                    }
                    return resolve({
                        status: STATUS_REQUEST_NO_INTERNET,
                        http_status: error.response.status,
                        response_status: false,
                        response: null,
                        error: 'You are not connected to internet',
                    });
                } else {
                    if (req.toast) {
                        req.toast(WR_TOAST_ERROR, 'Error occurred');
                    }
                    return resolve({
                        status: STATUS_REQUEST_ERROR,
                        http_status: error.response.status,
                        response_status: false,
                        response: null,
                        error: 'Error occurred',
                    });
                }
            });
    });
}
