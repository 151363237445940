import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TabI from '../../interfaces/app/tabI';

interface stateType {
    tab: TabI | null;
}

let initialState: stateType = {
    tab: null,
};

const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        REHYDRATE(state, action: PayloadAction<TabI>) {
            state.tab = action.payload;
        },
        addTab(state, action: PayloadAction<TabI>) {
            state.tab = action.payload;
        },
    },
});

export const { addTab } = tabSlice.actions;
export default tabSlice.reducer;
