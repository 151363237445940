import React, { FormEvent, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import * as auth from '../../services/auth.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { addUser } from '../../redux/slices/user.slice';
import Notifications, { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';
import Button from 'react-bootstrap/Button';
import { Img } from '../../components/common/Img';

function SignUp(props: any) {
    const [error, setError] = useState('');
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = (e: FormEvent) => {
        setError('');
        e.preventDefault();
        const invalid = Object.entries(userData).find(
            ([key, value]) => value === ''
        );
        if (invalid) {
            setError(invalid[0] + ' can not be empty');
            return;
        }
        setLoading(true);
        auth.SignUp(userData, loader).then((res) => {
            setLoading(false);
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    dispatch(
                        addUser({
                            id: res.response.id,
                            name: res.response.first_name,
                            email: res.response.email,
                            mobile: res.response.mobile,
                            role: res.response.role,
                            subscribed: res.response.subscribed,
                            isLoggedIn: true,
                        })
                    );
                    props.onHide();
                    props.onSuccess();
                    history.push('/');
                } else {
                    e.preventDefault();
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                e.preventDefault();
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.name === 'email'){
            setUserData({ ...userData, email: e.target.value.toLowerCase() });
            return;
        }
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    return (
        <Modal {...props} size="md" centered>
            <NewIdeaBox>
                <Notifications />
                <Logo>
                    <Img alt="StoxPathshala" src="logo.png" width="100px" />
                </Logo>
                <TitleBar>
                    <Title>Sign Up</Title>
                </TitleBar>
                <CloseIcon onClick={props.onHide}>X</CloseIcon>
                <form onSubmit={onSubmit}>
                    <div>
                        <label style={{ display: 'none' }}>Name</label>
                        <FormInput
                            type="text"
                            onChange={handleChange}
                            name="name"
                            placeholder="Enter your name"
                            value={userData.name}
                        />
                    </div>
                    <div>
                        <label style={{ display: 'none' }}>Email</label>
                        <FormInput
                            type="text"
                            onChange={handleChange}
                            name="email"
                            placeholder="Enter your email"
                            value={userData.email}
                        />
                    </div>
                    <div>
                        <label style={{ display: 'none' }}>Mobile</label>
                        <FormInput
                            type="text"
                            onChange={handleChange}
                            name="mobile"
                            placeholder="Enter your mobile number"
                            value={userData.mobile}
                        />
                    </div>
                    <div>
                        <label style={{ display: 'none' }}>Password</label>
                        <FormInput
                            type="password"
                            onChange={handleChange}
                            name="password"
                            placeholder="Enter password"
                            value={userData.password}
                        />
                    </div>
                    <ButtonBox>
                        <SubmitButton type="submit">Sign up</SubmitButton>
                    </ButtonBox>
                </form>
                <SpinLoader>
                    {loading && (
                        <Spinner animation="border" role="status"></Spinner>
                    )}
                </SpinLoader>
                {error && <ErrorMsg>{error}</ErrorMsg>}
                <LoginButtonBox>
                    Already have an account?{' '}
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            props.onHide();
                            props.switchToLogin();
                        }}
                    >
                        Login
                    </Button>
                </LoginButtonBox>
            </NewIdeaBox>
        </Modal>
    );
}
const NewIdeaBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding: 0 50px 0 50px;
`;
const SpinLoader = styled.div`
    display: flex;
    justify-content: center;
`;
const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
const TitleBar = styled.div`
    display: flex;
    justify-content: center;
`;
const CloseIcon = styled.div`
    position: absolute;
    right: 50px;
    float: right;
    top: 30px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
`;
const Logo = styled.div`
    margin-top: 10px;
`;
const LoginButtonBox = styled.div`
    margin: 20px 0 20px 0;
`;
const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SubmitButton = styled.button`
    padding: 5px 25px;
    background-color: gray;
    border: none;
    color: white;
    font-size: 18px;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const FormInput = styled.input`
    width: 100%;
    background: #f9f8fb;
    height: 50px;
    border: 0px solid #f9f8fb;
    font-size: 18px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const ErrorMsg = styled.div`
    color: #d8000c;
    background-color: #ffd2d2;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
`;
export default SignUp;
