import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StockObservationI from '../../interfaces/app/stockobservationI';

let initialState: Array<StockObservationI> = [];

const stockobservationSlice = createSlice({
    name: 'stockobservation',
    initialState,
    reducers: {
        addStockObservation(state, action: PayloadAction<StockObservationI>) {
            state.unshift(action.payload);
        },
        logoutstockobservation(state) {
            return (state = []);
        },
    },
});

export const { addStockObservation, logoutstockobservation } =
    stockobservationSlice.actions;
export default stockobservationSlice.reducer;
