export const deployment = process.env.DEPLOYMENT || "development";
export const apiUrl = process.env.NODE_ENV === "production" ? "https://stoxpathshala.com/api" : "http://localhost:3000";

export const urlAuthSignup = "/v1/auth/signup";
export const urlAuthSignin = "/v1/auth/signin";
export const urlAuthChangePassword = "/v1/user/password";
export const urlAuthResetPassword = "/v1/auth/reset_password";
export const urlAuthResetNewPassword = "/v1/auth/reset_user_password";
export const urlAuthGetLogin = "/v1/auth/getlogin";
export const urlAuthLogout = "/v1/auth/logout";

export const urlCalculator = "/v1/calculator/calculate";
export const urlUpdateCalculatorInput = "/v1/calculator/input/set-data";
export const urlGetCalculatorEquityData = "/v1/calculator/get-data";
export const urlGetCalculatorInputSuggestion = "/v1/calculator/trend-trading-calculator";

export const urlAdminUserInfo = "/v1/admin/userinfo";
export const urlAdminActiveUsers = "/v1/admin/activeusers";
export const urlAdminCalculatorUsers = "/v1/admin/calculatorusers";
export const urlAdminStockDeliveryServiceUsers =
  "/v1/admin/stockdeliveryserviceusers";
export const urlAdminStockObservationServiceUsers =
  "/v1/admin/stockobservationserviceusers";

export const urlAdminUpdateUserPassword = "/v1/admin/userpassword";
export const urlAdminDeactivateUser = "/v1/admin/userdeactivate";
export const urlAdminDeactivateCalculator =
  "/v1/admin/userdeactivatecalculater";
export const urlAdminDeactivateStockDelivery =
  "/v1/admin/userdeactivatebeststocks";
export const urlAdminDeactivateStockObservation =
  "/v1/admin/userdeactivatestockobservationservice";

export const urlAdminActivateCalculator = "/v1/admin/useractivatecalculater";
export const urlAdminActivateStockDeliveryService =
  "/v1/admin/useractivatebeststocks";
export const urlAdminActivateStockObservationService =
  "/v1/admin/useractivatestockobservationservice";

export const urlGetStockInfoById = "/v1/bestdeliverystocks/get-stock-info";
export const urlAddNewStockInfo = "/v1/bestdeliverystocks/addstockinfo";
export const urlUpdateStockInfo = "/v1/bestdeliverystocks/update-stock-info";
export const urlSyncStockInfo = "/v1/bestdeliverystocks/syncstockinfo";
export const urlDeleteStockInfo = "/v1/bestdeliverystocks/deletestockinfo";

export const urlGetStockObservationById =
  "/v1/stockobservationservice/get-stock-observation";
export const urlAddNewStockObservation =
  "/v1/stockobservationservice/addstockobservationinfo";
export const urlUpdateStockObservation =
  "/v1/stockobservationservice/update-stock-observation-info";
export const urlSyncStockObservation =
  "/v1/stockobservationservice/syncstockobservationinfo";
export const urlDeleteStockObservation =
  "/v1/stockobservationservice/deletestockobservationinfo";

export const urlMasterClassSubscription =
  "/v1/subscribe/master_class_subscription";
export const urlNewsLetterSubscription =
  "/v1/subscribe/newsletter_subscription";

export const urlRazorpayOrderid = "/v1/razorpay/orderid";
export const urlRazorpayUpdatePaymentStatus =
  "/v1/razorpay/update_payment_status";
export const urlRazorpayUpdateFailedPaymentStatus =
  "/v1/razorpay/update_failed_payment_status";
