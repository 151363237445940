import React, { Fragment, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { notify } from 'react-notify-toast';
import { Controller, useForm } from 'react-hook-form';
import * as subscription from '../../services/subscription.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { MasterClassSubscriptionForm } from '../../services/subscription.service';
import { useHistory } from 'react-router';
// import moment from 'moment';

function MasterClassSubscription() {
    const { control, handleSubmit, register } = useForm();
    const history = useHistory();
    // const [day, setDay] = useState(0);
    // const [hour, setHour] = useState(0);
    // const [minute, setMinute] = useState(0);
    // const [second, setSecond] = useState(0);

    // let setTime = () => {
    //     let now = moment();
    //     var end = moment('2021-03-13, 11:00:00'); // another date
    //     var duration = moment.duration(end.diff(now));
    //     setDay(Math.floor(duration.asDays()));
    //     setHour(
    //         Math.floor(duration.asHours() - Math.floor(duration.asDays()) * 24)
    //     );
    //     setMinute(
    //         Math.floor(
    //             duration.asMinutes() - Math.floor(duration.asHours()) * 60
    //         )
    //     );
    //     setSecond(
    //         Math.floor(
    //             duration.asSeconds() - Math.floor(duration.asMinutes()) * 60
    //         )
    //     );
    // };

    const onSubmit = (data: any) => {
        const inputForm: MasterClassSubscriptionForm = {
            name: data.name,
            email: data.email.toLowerCase(),
            number: data.number,
            state: data.state,
        };
        subscription.MasterClassSubscription(inputForm, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    history.push('/subscription-thankyou');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    useEffect(() => {
        // const interval = setInterval(() => {
        //     setTime();
        // }, 1000);
        // return () => clearInterval(interval);
    }, []);
    return (
        <Fragment>
            <Header />
            <PageContainer>
                <ClassInfo>
                    <SeminarInfo>
                        <WhenInfo>
                            <DateInfo>
                                <WhenInfoTitle>When:</WhenInfoTitle>
                                <WhenInfoValue>13 Mar 2021</WhenInfoValue>
                            </DateInfo>
                            <TimeInfo>
                                <WhenInfoTitle>Hour:</WhenInfoTitle>
                                <WhenInfoValue>11:00 AM</WhenInfoValue>
                            </TimeInfo>
                        </WhenInfo>
                        <MasterClassTitle>
                            Master Class - Bangalore
                        </MasterClassTitle>
                        <MasterClassDescription>
                            Where you will learn about the trading. After this
                            master class you will be able to trade like
                            professional
                        </MasterClassDescription>
                        <TimeLeftBlock>
                            <TimeLeftBlockHeading>
                                Seminar/Webinar starts in:
                            </TimeLeftBlockHeading>
                            <TimeLeftRow>
                                <TimeLeftRowBlock>
                                    <TimeLeftRowBlockValue>
                                        {0}
                                    </TimeLeftRowBlockValue>
                                    <TimeLeftRowBlockType>
                                        Days
                                    </TimeLeftRowBlockType>
                                </TimeLeftRowBlock>
                                <TimeLeftRowBlock>
                                    <TimeLeftRowBlockValue>
                                        {0}
                                    </TimeLeftRowBlockValue>
                                    <TimeLeftRowBlockType>
                                        Hours
                                    </TimeLeftRowBlockType>
                                </TimeLeftRowBlock>
                                <TimeLeftRowBlock>
                                    <TimeLeftRowBlockValue>
                                        {0}
                                    </TimeLeftRowBlockValue>
                                    <TimeLeftRowBlockType>
                                        Minutes
                                    </TimeLeftRowBlockType>
                                </TimeLeftRowBlock>
                                <TimeLeftRowBlock>
                                    <TimeLeftRowBlockValue>
                                        {0}
                                    </TimeLeftRowBlockValue>
                                    <TimeLeftRowBlockType>
                                        Seconds
                                    </TimeLeftRowBlockType>
                                </TimeLeftRowBlock>
                            </TimeLeftRow>
                            <button>Learn More</button>
                        </TimeLeftBlock>
                    </SeminarInfo>
                    <InputForm>
                        <InputFormImage>
                            <img
                                style={{
                                    width: '100%',
                                    borderRadius: '5px 5px 0 0',
                                    height: '200px',
                                }}
                                // src="holder.js/800x400?text=Third slide&bg=20232a"
                                src={require('../../images/finedu_consultancy_login.png')}
                                alt={'Finedu Consultancy'}
                            />
                        </InputFormImage>
                        <Form>
                            <Controller
                                as={StyledFormControl}
                                name="name"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="Name"
                                ref={register({ required: true })}
                            />
                            <Controller
                                as={StyledFormControl}
                                name="number"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="Mobile number"
                                ref={register({ required: true })}
                            />
                            <Controller
                                as={StyledFormControl}
                                name="email"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="Email"
                                ref={register({ required: true })}
                            />

                            <Controller
                                as={StyledFormControl}
                                name="state"
                                control={control}
                                defaultValue=""
                                type="text"
                                placeholder="State"
                                ref={register({ required: true })}
                            />
                        </Form>
                        <ButtonBox onClick={handleSubmit(onSubmit)}>
                            Register Now
                        </ButtonBox>
                    </InputForm>
                </ClassInfo>
                <BenefitInfo>
                    <BenefitInfoHeading>
                        What will you learn?
                    </BenefitInfoHeading>
                    <BenefitInfoRow>
                        <BenefitInfoRowBlock>
                            <EmojiObjectsIcon />
                            <BenefitInfoRowBlockHeading>
                                Benefit 1
                            </BenefitInfoRowBlockHeading>
                            <BenefitInfoRowBlockDescription>
                                Trade like Professionals
                            </BenefitInfoRowBlockDescription>
                        </BenefitInfoRowBlock>
                        <BenefitInfoRowBlock>
                            <EmojiObjectsIcon />
                            <BenefitInfoRowBlockHeading>
                                Benefit 2
                            </BenefitInfoRowBlockHeading>
                            <BenefitInfoRowBlockDescription>
                                Learn secret Stock Selection Method
                            </BenefitInfoRowBlockDescription>
                        </BenefitInfoRowBlock>
                        <BenefitInfoRowBlock>
                            <EmojiObjectsIcon />
                            <BenefitInfoRowBlockHeading>
                                Benefit 3
                            </BenefitInfoRowBlockHeading>
                            <BenefitInfoRowBlockDescription>
                                Master the Art of Swing Trading
                            </BenefitInfoRowBlockDescription>
                        </BenefitInfoRowBlock>
                        <BenefitInfoRowBlock>
                            <EmojiObjectsIcon />
                            <BenefitInfoRowBlockHeading>
                                Benefit 4
                            </BenefitInfoRowBlockHeading>
                            <BenefitInfoRowBlockDescription>
                                Learn the Art of Wealth Creation
                            </BenefitInfoRowBlockDescription>
                        </BenefitInfoRowBlock>
                    </BenefitInfoRow>
                </BenefitInfo>
            </PageContainer>
            <Footer />
        </Fragment>
    );
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 100px 50px;
    @media (max-width: 575.98px) {
        margin: 20px;
    }
`;
const StyledFormControl = styled(Form.Control)`
    background: #f9f8fb;
    height: 40px;
    width: 25vw;
    font-size: 18px;
    margin-top: 15px;
    padding: 0 10px 0 10px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
    @media (max-width: 767.98px) {
        width: 70vw;
    }
`;
const InputFormImage = styled.div`
    width: 30vw;
    @media (max-width: 767.98px) {
        width: 80vw;
    }
`;
const ButtonBox = styled.div`
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    bottom: 50px;
    width: 25vw;
    height: 40px;
    border-radius: 5px;
    color: white;
    background: black;
    margin: 20px 0px 10px;
    outline: none;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 5px;
    @media (max-width: 767.98px) {
        width: 70vw;
    }
`;
const ClassInfo = styled.div`
    display: flex;
    @media (max-width: 767.98px) {
        flex-direction: column;
    }
`;
const BenefitInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;
const SeminarInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 45vw;
    @media (max-width: 767.98px) {
        width: 80vw;
    }
`;
const InputForm = styled.div`
    border: 1px solid rgb(101, 101, 101);
    border-radius: 5px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767.98px) {
        width: 80vw;
    }
`;
const WhenInfo = styled.div`
    display: flex;
    flex-direction: column;
`;
const DateInfo = styled.div`
    display: flex;
`;
const TimeInfo = styled.div`
    display: flex;
`;
const WhenInfoTitle = styled.div`
    min-width: 80px;
`;
const WhenInfoValue = styled.div`
    font-weight: bold;
`;
const MasterClassTitle = styled.div`
    font-weight: 700;
    font-size: 30px;
    margin: 20px 0px;
`;
const MasterClassDescription = styled.div`
    padding: 30px 30px 30px 0px;
`;
const TimeLeftBlock = styled.div`
    margin: 20px 0px;
`;
const TimeLeftBlockHeading = styled.div`
    font-weight: bold;
`;
const TimeLeftRow = styled.div`
    display: flex;
    margin: 10px;
`;
const TimeLeftRowBlock = styled.div`
    margin: 0px 5px;
`;
const TimeLeftRowBlockValue = styled.div`
    width: 40px;
    height: 40px;
    border: 1px solid rgb(104, 104, 104);
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    border-radius: 4px;
`;
const TimeLeftRowBlockType = styled.div`
    font-size: 10px;
    color: rgb(104, 104, 104);
`;
const BenefitInfoHeading = styled.div`
    font-weight: bold;
    font-size: 30px;
    text-align: center;
`;
const BenefitInfoRow = styled.div`
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    margin: 20px 0px 10px;
    justify-content: center;
`;
const BenefitInfoRowBlock = styled.div`
    padding: 0 20px 0 20px;
    min-width: 300px;
`;
const BenefitInfoRowBlockHeading = styled.div`
    font-weight: bold;
    margin: 10px 0px;
`;
const BenefitInfoRowBlockDescription = styled.div``;
export default MasterClassSubscription;
