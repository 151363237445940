import React, { FormEvent, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import * as auth from '../../services/auth.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { loader } from '../../redux/slices/app.slice';
import { useDispatch } from 'react-redux';
import { addUser } from '../../redux/slices/user.slice';
import Notifications, { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';
import Button from 'react-bootstrap/Button';
import { Img } from '../../components/common/Img';

function Login(props: any) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!email) {
            setError("Email can't be empty");
            return;
        }
        if (!password) {
            setError("Password can't be empty");
            return;
        }
        setLoading(true);
        setError('');
        const data = {
            email: email.toLowerCase(),
            password,
        };
        auth.SignIn(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                setLoading(false);
                if (res.response_status) {
                    // toast.success("Successfully logged in");
                    dispatch(
                        addUser({
                            id: res.response.id,
                            name: res.response.name,
                            email: res.response.email,
                            mobile: res.response.mobile,
                            role: res.response.role,
                            subscribed: res.response.subscribed,
                            isLoggedIn: true,
                        })
                    );
                    props.onHide();
                    props.onSuccess();
                    history.push('/');
                } else {
                    setLoading(false);
                    for (let i = 0; i < res.error.length; i++) {
                        setError(res.error[0]['msg']);
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                setLoading(false);
                setError('Some error occurred! Try again.');
                notify.show('Connection Error', 'error', 3000);
            }
            // notify.show('Toasty!');
            // props.onHide();
        });
    };

    return (
        <Modal {...props} size="md" centered>
            <Notifications />
            <NewIdeaBox>
                <Logo>
                    <Img alt="StoxPathshala" src="logo.png" width="100px" />
                </Logo>
                <TitleBar>
                    <Title>Login</Title>
                </TitleBar>
                <CloseIcon onClick={props.onHide}>X</CloseIcon>
                <form onSubmit={onSubmit}>
                    <div>
                        <label style={{ display: 'none' }}>Email</label>
                        <FormInput
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            placeholder="Enter email"
                            value={email}
                        />
                    </div>
                    <div>
                        <label style={{ display: 'none' }}>Password</label>
                        <FormInput
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            placeholder="Enter password"
                            value={password}
                        />
                    </div>
                    <ButtonBox>
                        <SubmitButton type="submit">Login</SubmitButton>
                    </ButtonBox>
                </form>

                <SpinLoader>
                    {loading && (
                        <Spinner animation="border" role="status"></Spinner>
                    )}
                </SpinLoader>
                {error && <ErrorMsg>{error}</ErrorMsg>}
                <LoginButtonBox>
                    Forgot password?{' '}
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            props.onHide();
                            props.swtichtoResetPassword();
                        }}
                    >
                        Reset here
                    </Button>
                </LoginButtonBox>
                <LoginButtonBox>
                    Didn't have an account yet?{' '}
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            props.onHide();
                            props.switchToSignup();
                        }}
                    >
                        Sign up
                    </Button>
                </LoginButtonBox>
            </NewIdeaBox>
        </Modal>
    );
}
const Logo = styled.div`
    position: absolute;
    margin-top: 20px;
`;
const NewIdeaBox = styled.div`
    border-radius: 20px;
    padding: 0 50px 50px 50px;
`;
const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
const TitleBar = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
`;
const ErrorMsg = styled.div`
    color: #d8000c;
    background-color: #ffd2d2;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
`;
const CloseIcon = styled.div`
    position: relative;
    right: 10px;
    float: right;
    margin-top: -25px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
`;
const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SubmitButton = styled.button`
    padding: 5px 25px;
    background-color: gray;
    border: none;
    color: white;
    font-size: 18px;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const LoginButtonBox = styled.div`
    margin: 20px 0 20px 0;
`;
const SpinLoader = styled.div`
    display: flex;
    justify-content: center;
`;
const FormInput = styled.input`
    width: 100%;
    background: #f9f8fb;
    height: 50px;
    border: 0px solid #f9f8fb;
    font-size: 18px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
export default Login;
