import React, { Fragment } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CarouselHome from '../../components/Carousel/CarouselHome';
import HighlightMasterClass from '../../components/HighlightMasterClass/HighlightMasterClass';

function Home() {
    return (
        <Fragment>
            <Header />
            <CarouselHome />
            {/* <Flyer /> */}
            {/* <StockMarketClass /> */}
            <HighlightMasterClass />
            <Footer />
        </Fragment>
    );
}

export default Home;
