import React, { FormEvent, Fragment, useState } from "react";
import { useHistory } from 'react-router';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styled from 'styled-components';
import * as auth from '../../services/auth.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import { ResetNewPasswordForm } from '../../services/auth.service';
import { Img } from "../../components/common/Img";

function ResetPasswordPage(props: any) {
    const [password, setPassword] = useState({
        password: '',
        confirm_password: '',
    });
    const token = props.match.params.token;
    const [error, setError] = useState('');

    const history = useHistory();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError('');
        if (password.password === '' || password.confirm_password === '') {
            setError("Password can't be empty");
            return;
        }
        if (password.password !== password.confirm_password) {
            setError("Password didn't match");
            return;
        }
        const data_form: ResetNewPasswordForm = {
            password: password.password,
            token: token,
        };
        auth.ResetNewPassword(data_form, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    history.push('/password-changed-successfully');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        setError(res.error[i]['msg']);
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                setError('Some error occurred! Try again');
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };
    return (
        <Fragment>
            <Header />
            <Container>
                <Logo>
                    <Img alt="StoxPathshala" src="logo.png" width="100px" />
                </Logo>
                <TitleBar>
                    <Title>Reset Password</Title>
                </TitleBar>
                <form onSubmit={onSubmit}>
                    <div>
                        <label style={{ display: 'none' }}>Password</label>
                        <FormInput
                          type="password"
                          onChange={handleChange}
                          name="password"
                          placeholder="Enter password"
                          value={password.password}
                        />
                    </div>
                    <div>
                        <label style={{ display: 'none' }}>Confirm Password</label>
                        <FormInput
                          type="password"
                          onChange={handleChange}
                          name="confirm_password"
                          placeholder="Confirm password"
                          value={password.confirm_password}
                        />
                    </div>
                    <ButtonBox>
                        <SubmitButton type="submit">Reset Password</SubmitButton>
                    </ButtonBox>
                </form>
                {error && <ErrorMsg>{error}</ErrorMsg>}
            </Container>
            <Footer />
        </Fragment>
    );
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
`;
const Logo = styled.div`
    margin-top: 20px;
`;
const TitleBar = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
`;
const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SubmitButton = styled.button`
    padding: 5px 25px;
    background-color: gray;
    border: none;
    color: white;
    font-size: 18px;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const ErrorMsg = styled.div`
    color: #d8000c;
    background-color: #ffd2d2;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
`;
const FormInput = styled.input`
    width: 100%;
    background: #f9f8fb;
    height: 50px;
    border: 0px solid #f9f8fb;
    font-size: 18px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
export default ResetPasswordPage;
