import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserI from '../../interfaces/app/userI';

interface stateType {
    user: UserI | null;
}

let initialState: stateType = {
    user: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        REHYDRATE(state, action: PayloadAction<UserI>) {
            state.user = action.payload;
        },
        addUser(state, action: PayloadAction<UserI>) {
            state.user = action.payload;
        },
        logout(state, action: PayloadAction<UserI>) {
            state.user = null;
        },
        offline(state, action: PayloadAction<UserI>) {
            if (state.user !== null) {
                state.user.isLoggedIn = false;
            }
        },
    },
});

export const { addUser, logout, offline } = userSlice.actions;
export default userSlice.reducer;
