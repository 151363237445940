import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

function QRCode(props: any) {
    return (
        <Modal
            {...props}
            // size="md"
            // aria-labelledby="contained-modal-title-vcenter"
            // centered
        >
            <ImageBox>
                <img
                    style={{ width: '100%' }}
                    // src="holder.js/800x400?text=Third slide&bg=20232a"
                    src={require('../../images/payment/qr_code.jpeg')}
                    alt={'qr code'}
                />
            </ImageBox>
        </Modal>
    );
}
const ImageBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: None;
    @media (max-width: 575.98px) {
        width: 100%;
        background: none;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 100%;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
export default QRCode;
