import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { StockInfoId, UpdateStockInfo } from '../../services/beststock.service';
import * as beststock from '../../services/beststock.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import RichTextEditor from 'react-rte';

function EditStockInfo(props: any) {
    const id = props.match.params.id;
    const history = useHistory();
    let value = RichTextEditor.createEmptyValue();
    let [stockInfo, setStockInfo] = useState(value);
    let [title, setTitle] = useState('');
    function handleTitleChange(e: any) {
        setTitle(e.target.value);
    }
    function onChange(c: any) {
        setStockInfo(c);
        value = c;
    }
    function updateStockInfo() {
        let inputForm: UpdateStockInfo = {
            id: id,
            title: title,
            content: stockInfo.toString('html'),
        };
        if (stockInfo.toString('html') === '' || title === '') {
            notify.show('Enter title and description', 'error', 3000);
            return;
        }
        beststock.UpdateStockInfo(inputForm).then((res: any) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    history.push('/bestdeliverystocks');
                    notify.show('Stock Info Added', 'success', 1000);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            }
        });
    }
    function getStockInfoById() {
        const data: StockInfoId = {
            id: parseInt(id),
        };
        beststock.GetStockInfoById(data).then((res: any) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setStockInfo(
                        RichTextEditor.createValueFromString(
                            res.response.stockinfolist.content,
                            'html'
                        )
                    );
                    setTitle(res.response.stockinfolist.title);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            }
        });
    }
    useEffect(() => {
        getStockInfoById();
    }, []);
    return (
        <Fragment>
            <Header />
            <Title>Edit Delivery Stocks</Title>
            <StyledForm>
                <StyledFormGroup controlId="stockformtitle">
                    <BoldTitle>
                        <StyledFormLabel>Title</StyledFormLabel>
                    </BoldTitle>
                    <StyledFormControl
                        as="input"
                        type="text"
                        placeholder="Title"
                        defaultValue={title}
                        onChange={handleTitleChange}
                    />
                </StyledFormGroup>
                <StyledFormGroup controlId="stockinformation">
                    <BoldTitle>
                        <StyledFormLabel>About</StyledFormLabel>
                    </BoldTitle>
                    <RichTextEditor value={stockInfo} onChange={onChange} />
                </StyledFormGroup>
                <ButtonBox onClick={() => updateStockInfo()}>Update</ButtonBox>
            </StyledForm>
            <Footer />
        </Fragment>
    );
}
const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const StyledForm = styled(Form)`
    padding: 15px;
    margin: auto;
    width: 80%;
`;
const StyledFormGroup = styled(Form.Group)`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
const StyledFormLabel = styled(Form.Label)`
    font-weight: bold;
`;
const StyledFormControl = styled(Form.Control)`
    background: white;
    height: 40px;
    border: 1px solid #d7dbdd;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 0 20px 0 20px;
    &:focus {
        outline: none;
    }
`;
const BoldTitle = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`;
const ButtonBox = styled.div`
    text-align: center;
    align-self: center;
    cursor: pointer;
    bottom: 50px;
    width: 100px;
    height: 30px;
    border-radius: 8px;
    color: white;
    background: grey;
    margin: 20px 0 30px 0;
    padding: 5px 0 0 0;
`;
export default EditStockInfo;
