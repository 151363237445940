import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import * as auth from '../../services/auth.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { UpdateCalculatorInputForm } from '../../services/auth.service';


function UpdateCalculatorInput(){
    const history = useHistory();
    const [input, setInput] = useState("");
    const [error, setError] = useState("");
    function handleChange(event:any) {
        setInput(event.target.value);
    }
    function handleSubmit(e:any) {
        const inputData:UpdateCalculatorInputForm = {
            "data":JSON.parse(input)
        }
        auth.UpdateCalcutorInput(inputData, loader).then((res) => {

            if(res.status === STATUS_REQUEST_SUCCESS){
                if(res.response_status){
                    history.push("/admin");
                }else{
                    setError("Some error occurred! Try again");
                }
            }
            else {
                setError("Some error occurred! Try again");
            }
        });
        e.preventDefault();
    }
    return(
        <>
            <Header/>
            <div style={{display:'flex', justifyContent:'center', margin:'30px 0 30px 0'}}>
                <form onSubmit={handleSubmit} style={{display:'flex', flexDirection:'column'}}>
                    <label>
                        <textarea value={input} onChange={handleChange} style={{width:'90vw', height:'50vh'}}/>
                    </label>
                    <input type="submit" value="Submit" style={{width:'100px', fontWeight:'bold'}}/>
                </form>
            </div>

            {error && <ErrorMsg>{error}</ErrorMsg>}
            <Footer />
        </>
    );
}
const ErrorMsg = styled.div`
    color: #d8000c;
    background-color: #ffd2d2;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
`;
export default UpdateCalculatorInput;
