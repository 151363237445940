import React from 'react';
import Header from '../../components/Header/Header';
import styled from 'styled-components';
import { Img } from "../../components/common/Img";

function NeedToLoginBeforeAccessMasterClass() {
    return (
        <div>
            <Header />
            <DataBox>
                <Title>Please Login or SignUp to Continue...</Title>
                <CenterContaiener>
                  <Img src='masterClass/class.jpg' alt='Master class StoxPathshala' width='100%'/>
                </CenterContaiener>
            </DataBox>
        </div>
    );
}
const Title = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: #808b96;
    align-text: center;
    margin-bottom: 20px;
`;
const DataBox = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 100%;
        margin-left: 20px;
    }
    @media (min-width: 576px) {
        margin: 50px 0 0 0;
        padding: 0 0 0 50px;
    }
`;
const CenterContaiener = styled.div`
    display: flex;
`;
export default NeedToLoginBeforeAccessMasterClass;
