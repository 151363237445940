import React, { FormEvent, useState } from "react";
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import * as auth from '../../services/auth.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { loader } from '../../redux/slices/app.slice';
import Notifications, { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';
import { Img } from '../../components/common/Img';

function ChangePassword(props: any) {
    const [password, setPassword] = useState({
        old_password: '',
        new_password: '',
    });
    const [error, setError] = useState('');
    const history = useHistory();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        auth.ChangePassword(password, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.onHide();
                    // props.onSuccess();
                    history.push('/password-changed-successfully');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        setError('Some error occurred! Try again');
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                setError('Some error occurred! Try again');
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };
    return (
        <Modal
            {...props}
            size="md"
            // aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Notifications />
            <NewIdeaBox>
                <Logo>
                    <Img alt="StoxPathshala" src="logo.png" width="100px" />
                </Logo>
                <TitleBar>
                    <Title>Change Password</Title>
                </TitleBar>
                <CloseIcon onClick={props.onHide}>X</CloseIcon>
                <form onSubmit={onSubmit}>
                    <div>
                        <label style={{ display: 'none' }}>Old Password</label>
                        <FormInput
                            type="text"
                            onChange={handleChange}
                            name="old_password"
                            placeholder="Enter old password"
                            value={password.old_password}
                        />
                    </div>
                    <div>
                        <label style={{ display: 'none' }}>New Password</label>
                        <FormInput
                            type="text"
                            onChange={handleChange}
                            name="new_password"
                            placeholder="Enter new password"
                            value={password.new_password}
                        />
                    </div>
                    <ButtonBox>
                        <SubmitButton type="submit">Update Password</SubmitButton>
                    </ButtonBox>
                </form>
                {error && <ErrorMsg>{error}</ErrorMsg>}
            </NewIdeaBox>
        </Modal>
    );
}
const Logo = styled.div`
    position: absolute;
    margin-top: 20px;
`;
const ErrorMsg = styled.div`
    color: #d8000c;
    background-color: #ffd2d2;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
`;
const NewIdeaBox = styled.div`
    border-radius: 20px;
    padding: 0 50px 50px 50px;
`;
const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
const TitleBar = styled.div`
    margin-top: 80px;
    display: flex;
    justify-content: center;
`;
const CloseIcon = styled.div`
    position: relative;
    right: 10px;
    float: right;
    margin-top: -25px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
`;
const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SubmitButton = styled.button`
    padding: 5px 25px;
    background-color: gray;
    border: none;
    color: white;
    font-size: 18px;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const FormInput = styled.input`
    width: 100%;
    background: #f9f8fb;
    height: 50px;
    border: 0px solid #f9f8fb;
    font-size: 18px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
export default ChangePassword;
