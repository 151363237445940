import React, {Fragment, useEffect, useState} from "react";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BuyChartDonutVictory from "./buyChartDonutVictory";
import SellChartDonutVictory from "./sellChartDonutVictory";
import * as calculator from "../../services/calculator.service";
import {loader} from "../../redux/slices/app.slice";
import {STATUS_REQUEST_SUCCESS} from "../../services/common.service";
import {useForm} from "react-hook-form";
import Notification, {notify} from "react-notify-toast";
import {Input} from "../../services/calculator.service";
import Select from 'react-select';
import { Spinner } from 'react-bootstrap';

function GannChartJS(props: any) {
    const [buy, setBuy] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [sell, setSell] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [matrix, setMatrix] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('0');
    const [selected, setSelected] = useState(null);
    const [error, setError] = useState('');

    const [input, setInput] = useState("");
    const [dataFromJson, setDataFromjson] = useState([]);
    const inputForCalculate:Input = {
        input_value: "0"
    };
    const { handleSubmit } = useForm();

    const update = (res: any) => {
        setBuy(res.response['Buy']);
        setSell(res.response['Sell']);
        setMatrix(res.response['SwingMatrix']);
    };
    const handleChange = (selectedOption: any) => {
        setError('');
        setSelected(selectedOption);
        setValue(selectedOption.value);
    };
    const handleTextFocus = () => {
        setSelected(null);
        setValue('0');
    };
    const handleSelectFocus = () => {
        setInput('');
        setValue('0');
    };

    const onSubmit = () => {
        setLoading(true);
        if(value === '0') {
            setLoading(false);
            setError('Please select a value')
            return;
        }
        inputForCalculate.input_value = value.toString();
        calculator.Calculate(inputForCalculate, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                setLoading(false);
                if (res.response_status) {
                    update(res);
                } else {
                    notify.show('Enter stock name or value', 'error', 3000);
                }
            } else {
                setLoading(false);
                notify.show('Error, try logging in again', 'error', 3000);
            }
        });
    };
    useEffect(()=>{
        setLoading(true);
        // calculator.GetCalcutorInput({}, loader).then((res) => {
        //     setLoading(false);
        //     if(res.status === STATUS_REQUEST_SUCCESS){
        //         if(res.response_status) {
        //             setDataFromjson(res.response.data)
        //         }
        //         else{
        //             notify.show("Enter stock name or value", "error", 3000);
        //         }
        //     }
        //     else{
        //         setLoading(false);
        //         notify.show("Error, try logging in again", "error", 3000);
        //     }
        // })
    },[])

    return (
        <Fragment>
            <Header />
            <Title>Trend Trading Calculator</Title>
            <SubTitle>"Trend is your only Friend"</SubTitle>
            <StyledForm>
                <Form>
                    <Select
                        options={dataFromJson}
                        value={selected}
                        onChange={handleChange}
                        onFocus={handleSelectFocus}
                    />
                    or
                    <BottomValueRow>
                        <input
                            type="text"
                            name="text_input"
                            value={input}
                            placeholder="Enter stock value"
                            onFocus={handleTextFocus}
                            onChange={(e: any) => {
                                setError('');
                                setInput(e.target.value);
                                setValue(e.target.value);
                            }}
                            style={{ border: '1px solid #D5D3D8' }}
                        />
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                        >
                            Go
                        </Button>
                        <SpinLoader>
                            {loading && (
                                <Spinner
                                    animation="border"
                                    role="status"
                                ></Spinner>
                            )}
                        </SpinLoader>
                    </BottomValueRow>
                    <InformationAboutStock>
                        *For Future and Options/ International Shares/
                        Commodities/ Forex/ Cryptocurrencies, "Enter Last Price"
                    </InformationAboutStock>
                    <small style={{color:'red'}}>{error && error}</small>
                </Form>
            </StyledForm>
            <Container>
                <Notification />
                <DataBox>
                    <CenterContaiener>
                        <TitleLeft>Intraday Target</TitleLeft>
                        <ChartBox>
                            <DonutChartContainer>
                                <BuyChartDonutVictory s={buy} />
                                <BuyBox>
                                    <BuyTitle>Buy above -&nbsp;</BuyTitle>
                                    <BuyValue>{buy[0]}</BuyValue>
                                </BuyBox>
                                Stop Loss
                                <SellBox>
                                    <SellTitle>Sell below -&nbsp;</SellTitle>
                                    <SellValue>{sell[0]}</SellValue>
                                </SellBox>
                            </DonutChartContainer>
                            <DonutChartContainer>
                                <SellChartDonutVictory s={sell} />
                                <SellBox>
                                    <SellTitle>Sell below -&nbsp;</SellTitle>
                                    <SellValue>{sell[0]}</SellValue>
                                </SellBox>
                                Stop Loss
                                <BuyBox>
                                    <BuyTitle>Buy above -&nbsp;</BuyTitle>
                                    <BuyValue>{buy[0]}</BuyValue>
                                </BuyBox>
                            </DonutChartContainer>
                        </ChartBox>
                    </CenterContaiener>
                </DataBox>
                <PostionalBox>
                    <TitleRight>Position/Swing Target</TitleRight>
                    <ResistanceBoxContainer>
                        <ResistanceBox>
                            <ResistanceTitle>Target 3 -</ResistanceTitle>
                            <ResistanceValue>{matrix[0]}</ResistanceValue>
                        </ResistanceBox>
                        <ResistanceBox>
                            <ResistanceTitle>Target 2 -</ResistanceTitle>
                            <ResistanceValue>{matrix[1]}</ResistanceValue>
                        </ResistanceBox>
                        <ResistanceBox>
                            <ResistanceTitle>Target 1 -</ResistanceTitle>
                            <ResistanceValue>{matrix[2]}</ResistanceValue>
                        </ResistanceBox>
                    </ResistanceBoxContainer>
                    <PivotBoxContainer>
                        <PivotBox>
                            <PivotTitle>Breakout/Breakdown Level</PivotTitle>
                            <PivotValue>{matrix[3]}</PivotValue>
                        </PivotBox>
                    </PivotBoxContainer>
                    <SupportBoxContainer>
                        <SupportBox>
                            <SupportTitle>Target 1 -</SupportTitle>
                            <SupportValue>{matrix[4]}</SupportValue>
                        </SupportBox>
                        <SupportBox>
                            <SupportTitle>Target 2 -</SupportTitle>
                            <SupportValue>{matrix[5]}</SupportValue>
                        </SupportBox>
                        <SupportBox>
                            <SupportTitle>Target 3 -</SupportTitle>
                            <SupportValue>{matrix[6]}</SupportValue>
                        </SupportBox>
                    </SupportBoxContainer>
                </PostionalBox>
            </Container>
            <CommentBox>
                ** If Closing above T3 & T6, you can carry your positions
                overnight with SL T2 & T5 respectively **
            </CommentBox>
            <Footer />
        </Fragment>
    );
}
const StyledForm = styled(Form)`
    margin: 20px;
    width: 40%;
    z-index: 2;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const BottomValueRow = styled(Form)`
    display: flex;
    width: 100%;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const InformationAboutStock = styled.div`
    font-size: 12px;
    color: grey;
`;
const ChartBox = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;

const SellBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 200px;
    border: 1px solid red;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const SellTitle = styled.div``;
const SellValue = styled.div``;
const BuyBox = styled.div`
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    width: 200px;
    border: 1px solid green;
    border-radius: 8px;
    font-weight: bold;
`;
const BuyTitle = styled.div``;
const CommentBox = styled.div`
    font-size: 16px;
    margin-bottom: 100px;
    margin-left: 80px;
    color: #808b96;
    @media (max-width: 575.98px) {
        margin-left: 30px;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const BuyValue = styled.div`
    display: flex;
`;
const DonutChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 10px;
`;
const TitleLeft = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    background: #042f66;
    width: 40%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const TitleRight = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 20px;
    background: #042f66;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
`;
const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const SubTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const DataBox = styled.div`
    width: 75%;
    // align-items:center;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 100%;
        margin-left: 20px;
    }
    @media (min-width: 576px) {
        margin: 20px 0 0 0;
        padding: 0 0 0 50px;
    }
`;
const Container = styled.div`
    display: flex;
    margin-bottom: 10px;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const CenterContaiener = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const PostionalBox = styled.div`
    display: flex;
    flex-direction: column;
    // align-items:center;
    margin: 50px 0 0 50px;
    width: 30%;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const ResistanceBox = styled.div`
    display: flex;
    align-items: center;
`;
const ResistanceTitle = styled.div`
    font-size: 20px;
`;
const ResistanceValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const PivotBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const PivotTitle = styled.div`
    font-size: 20px;
`;
const PivotValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const ResistanceBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #abebc6;
    padding: 30px;
    border-radius: 8px;
    width: 330px;
    margin-top: 40px;
`;
const PivotBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #d6dbdf;
    margin: 10px 0 10px 0;
    padding: 20px;
    border-radius: 8px;
    width: 330px;
`;
const SupportBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #f5b7b1;
    padding: 30px;
    border-radius: 8px;
    width: 330px;
`;
const SupportBox = styled.div`
    display: flex;
    align-items: center;
`;
const SupportTitle = styled.div`
    font-size: 20px;
`;
const SupportValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const SpinLoader = styled.div`
    width: 50px;
`;
export default GannChartJS;
