import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppI from '../../interfaces/app/appI';

let initialState: AppI = {
    loading: false,
    syncing: false,
    do_sync: false,
    redirect_path: undefined,
    modal: {},
    dropdown: {},
    theme: 'light',
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        redirectPath(state, action: PayloadAction<string>) {
            state.redirect_path = action.payload;
        },
        loader(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        sync(state, action: PayloadAction<boolean>) {
            state.syncing = action.payload;
        },
        doSync(state, action: PayloadAction<boolean>) {
            state.do_sync = action.payload;
        },
        switchTheme(state) {
            state.theme = state.theme === 'dark' ? 'light' : 'dark';
        },
    },
});

export const { redirectPath, loader, sync, doSync, switchTheme } =
    appSlice.actions;
export default appSlice.reducer;
