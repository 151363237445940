import React, { Fragment } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { selectTheme } from '../../constants/theme.constant';
import styled from 'styled-components';
import { Img } from "../../components/common/Img";

function Blog() {
    return (
        <Fragment>
            <Header />
            <BlogContainer>
                <Title>Latest Articles From The Experts</Title>
                {/*<TitleDescription>*/}
                {/*    Focusing on Training the students as per the standards required by the Industry/Corporate.*/}
                {/*</TitleDescription>*/}
                <ContentBox>
                    <Thumbnail>
                        <Img alt='log/blog1.jpg' src='StoxPathshala Blog' width='100%'/>
                    </Thumbnail>
                    <Content>
                        <ContentDescription>
                            Don’t Follow Market Trends or Experts Blindly
                        </ContentDescription>
                        <ContentVideo>
                            Stock markets have always caught the fancy of
                            investors looking at inflation-beating returns and
                            wealth generation. There are some tips that will
                            help you to earn handsome revenue.
                            <br />
                            1. Understand Your Investor Profile
                            <br />
                            2. Avoid Emotion-Driven Investment Decisions
                        </ContentVideo>
                        <WatchButton>Read Full Article</WatchButton>
                    </Content>
                </ContentBox>
                <ContentBox>
                    <Thumbnail>
                        <Img alt='blog/blog2.png' src='StoxPathshala Blog' width='100%'/>
                    </Thumbnail>
                    <Content>
                        <ContentDescription>
                            Be Careful Before Using Borrowed Money to Invest
                        </ContentDescription>
                        <ContentVideo>
                            Stock markets have always caught the fancy of
                            investors looking at inflation-beating returns and
                            wealth generation. There are some tips that will
                            help you to earn handsome revenue.
                            <br />
                            1. Understand Your Investor Profile
                            <br />
                            2. Avoid Emotion-Driven Investment Decisions
                        </ContentVideo>
                        <WatchButton>Read Full Article</WatchButton>
                    </Content>
                </ContentBox>
            </BlogContainer>
            <Footer />
        </Fragment>
    );
}
const getConstants = selectTheme();
const BlogContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
`;
const Title = styled.div`
    font-size: 50px;
    font-family: ${getConstants.font};
    font-weight: bold;
    margin-top: 20px;
`;
const ContentBox = styled.div`
    display: flex;
    border-radius: 20px;
    background: #f9f8fb;
    padding: 0 0 30px 0;
    margin: 20px 50px 0 50px;
    box-shadow: 5px 5px 0px #c2bfc6;
    @media (max-width: 575.98px) {
        flex-direction: column;
        width: 90%;
    }
`;
const Thumbnail = styled.div`
    padding: 50px 50px 0 75px;
    @media (max-width: 575.98px) {
        padding: 10px;
        width: 100%;
        height: 300px;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 50px 0 25px;
`;
const ContentVideo = styled.div`
    font-family: ${getConstants.font};
    font-size: 18px;
    margin-top: 25px;
`;
const WatchButton = styled.div`
    width: 350px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    background: ${getConstants.t3Four};
    font-family: ${getConstants.font};
    margin-top: 20px;
    padding-top: 5px;
    border-radius: 30px;
    color: white;
`;
const ContentDescription = styled.div`
    font-size: 24px;
    text-transform: uppercase;
    font-family: ${getConstants.font};
    padding-top: 50px;
    font-weight: bold;
`;
export default Blog;
