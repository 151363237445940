import { Request } from './common.service';
import * as config from '../config';
import { urlGetCalculatorEquityData, urlGetCalculatorInputSuggestion } from "../config";

export interface Input {
    input_value: string;
}

export async function Calculate(
    form: Input,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlCalculator,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function GetCalcutorEquityData(form: {}, loader?: Function, toast?: Function){
    return await Request({
        url: config.urlGetCalculatorEquityData,
        method: "GET",
        form: form,
        loader: loader,
        toast: toast
    });
}
export async function GetCalcutorInputMultipleDataSet(searchParams:string, loader?: Function, toast?: Function){
    return await Request({
        url: urlGetCalculatorInputSuggestion+'?'+searchParams,
        method: "GET",
        form: {},
        loader: loader,
        toast: toast
    });
}