import React, { Fragment } from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Img } from "../../components/common/Img";

function DmatPage() {
    return (
        <Fragment>
            <Header />
            <Container>
                <Title>Open Your Free Trading Account Today.</Title>
                <TopRow>
                    <LeftTop>
                        <a
                            href="http://tinyurl.com/sa8s9cf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/angelbroking.png' alt="angel broking" width='100%' height='100%'/>
                        </a>
                    </LeftTop>
                    <LeftTop>
                        <a
                            href="https://tinyurl.com/yyda8p47"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/stoxkart.jpg' alt="StoxKart" width='100%' height='100%'/>
                        </a>
                    </LeftTop>
                    <RightTop>
                        <a
                            href="https://alicebluepartner.com/open-myaccount/?N=MN417"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/aliceblue.png' alt="alice blue" width='100%' height='100%'/>
                        </a>
                    </RightTop>
                    <RightTop>
                        <a
                            href="https://groww.app.link/K3Q4pTJ9H7"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/groww.png' alt="groww" width='100%' height='100%'/>
                        </a>
                    </RightTop>
                </TopRow>
                <BottomRow>
                    <LeftBottom>
                        <a
                            href="https://upstox.com/open-account/?f=L8LD"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/upstoxjpg.jpg' alt="upstox" width='100%' height='100%'/>
                        </a>
                    </LeftBottom>
                    <RightBottom>
                        <a
                            href="http://www.indiainfoline.com/campaigns/growthamplifiers/open-demat/?id=cA8kLT"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/iifl.jpeg' alt="iifl" width='100%' height='100%'/>
                        </a>
                    </RightBottom>
                    <RightBottom>
                        <a
                            href="https://zerodha.com/open-account?c=ZMPPBV"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img src='dmat/Zerodha.png' alt="Zerodha" width='100%' height='100%'/>
                        </a>
                    </RightBottom>
                </BottomRow>
            </Container>
            <Footer />
        </Fragment>
    );
}

const Title = styled(Form)`
    font-weight: bold;
    font-size: 40px;
`;
const Container = styled(Form)`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;
const TopRow = styled(Form)`
    display: flex;
    justify-content: center;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
`;
const BottomRow = styled(Form)`
    display: flex;
    justify-content: center;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
`;
const LeftTop = styled(Form)`
    width: 30%;
    margin-top: 20px;
    padding: 30px;
    @media (max-width: 575.98px) {
        width: 70%;
    }
`;
const RightTop = styled(Form)`
    width: 30%;
    margin-top: 20px;
    padding: 30px;
    @media (max-width: 575.98px) {
        width: 70%;
    }
`;
const LeftBottom = styled(Form)`
    width: 30%;
    margin-top: 30px;
    padding: 30px;
    @media (max-width: 575.98px) {
        width: 70%;
    }
`;
const RightBottom = styled(Form)`
    margin-top: 30px;
    padding: 30px;
    width: 30%;
    @media (max-width: 575.98px) {
        width: 70%;
    }
`;
export default DmatPage;
