import { Request } from './common.service';
import * as config from '../config';

export interface SignupForm {
    name: string;
    email: string;
    mobile: string;
    password: string;
}

export interface SigninForm {
    email: string;
    password: string;
}

export interface ChangePasswordForm {
    old_password: string;
    new_password: string;
}

export interface ResetPasswordForm {
    email: string;
}

export interface ResetNewPasswordForm {
    token: string;
    password: string;
}
export interface UpdateCalculatorInputForm {
    data: JSON;
}
export interface noParameter {}

export async function SignUp(
    form: SignupForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAuthSignup,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function SignIn(
    form: SigninForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAuthSignin,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function ChangePassword(
    form: ChangePasswordForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAuthChangePassword,
        method: 'PUT',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function UpdateCalcutorInput(
    form: UpdateCalculatorInputForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlUpdateCalculatorInput,
        method: 'PUT',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function ResetPassword(
    form: ResetPasswordForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAuthResetPassword,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function ResetNewPassword(
    form: ResetNewPasswordForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAuthResetNewPassword,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function GetLogin(form: {}, loader?: Function, toast?: Function) {
    return await Request({
        url: config.urlAuthGetLogin,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
};

export async function Logout(
    form: noParameter,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAuthLogout,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
