import React from 'react';
import Header from '../../components/Header/Header';
import styled from 'styled-components';

function NeedToLoginBeforeAccessStockObservation() {
    return (
        <div>
            <Header />
            <DataBox>
                <Title>Please Login or SignUp to Continue...</Title>
                <CenterContaiener>
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px 10px 0 0',
                        }}
                        src={require('../../images/stockObservation/stock-observation.jpg')}
                        alt={'finedu consultancy'}
                    />
                </CenterContaiener>
            </DataBox>
        </div>
    );
}
const Title = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: #808b96;
    align-text: center;
    margin-bottom: 20px;
`;
const DataBox = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 100%;
        margin-left: 20px;
    }
    @media (min-width: 576px) {
        margin: 50px 0 0 0;
        padding: 0 0 0 50px;
    }
`;
const CenterContaiener = styled.div`
    display: flex;
`;
export default NeedToLoginBeforeAccessStockObservation;
