import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StockInfoI from '../../interfaces/app/stockinfoI';

let initialState: Array<StockInfoI> = [];

const stockinfoSlice = createSlice({
    name: 'stockinfo',
    initialState,
    reducers: {
        addStockInfo(state, action: PayloadAction<StockInfoI>) {
            state.unshift(action.payload);
        },
        logoutstockinfo(state) {
            return (state = []);
        },
    },
});

export const { addStockInfo, logoutstockinfo } = stockinfoSlice.actions;
export default stockinfoSlice.reducer;
