import React from 'react';
import Header from '../../components/Header/Header';
import BuyChartDonutVictory from './buyChartDonutVictory';
import SellChartDonutVictory from './sellChartDonutVictory';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';

function NeedAuthorizationBeforeAccessPage() {
    const history = useHistory();
    const values = {
        buy: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        sell: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        swingMatrix: ['0', '0', '0', '0', '0', '0', '0'],
    };
    return (
        <div>
            <Header />
            <DataBox>
                <Title>
                    Please Contact 7827286174 or write us on
                    trendtradingcalculator@gmail.com to activate your account
                </Title>
                <Button onClick={() => history.push('/pay')}>
                    Subscribe to Stock Trend Trading Calculator
                </Button>
                <CenterContaiener>
                    <ChartBox>
                        <DonutChartContainer>
                            <BuyChartDonutVictory s={values['buy']} />
                            <BuyBox>
                                <BuyTitle>Buy above -&nbsp;</BuyTitle>
                                <BuyValue>0</BuyValue>
                            </BuyBox>
                        </DonutChartContainer>
                        <DonutChartContainer>
                            <SellChartDonutVictory s={values['sell']} />
                            <SellBox>
                                <SellTitle>Sell below -&nbsp;</SellTitle>
                                <SellValue>0</SellValue>
                            </SellBox>
                        </DonutChartContainer>
                    </ChartBox>
                </CenterContaiener>
            </DataBox>
        </div>
    );
}

const ChartBox = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;

const SellBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 200px;
    border: 1px solid red;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const SellTitle = styled.div``;
const SellValue = styled.div``;
const BuyBox = styled.div`
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    width: 200px;
    border: 1px solid green;
    border-radius: 8px;
    font-weight: bold;
`;
const BuyTitle = styled.div``;

const BuyValue = styled.div`
    display: flex;
`;
const DonutChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 10px;
`;
const Title = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: #808b96;
    align-text: center;
    margin-bottom: 20px;
`;
const DataBox = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 100%;
        margin-left: 20px;
    }
    @media (min-width: 576px) {
        margin: 50px 0 0 0;
        padding: 0 0 0 50px;
    }
`;

const CenterContaiener = styled.div`
    display: flex;
`;
export default NeedAuthorizationBeforeAccessPage;
