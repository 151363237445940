import React, { Fragment } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import * as stockObservation from '../../services/stockobservation.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import { StockOservationId } from '../../services/stockobservation.service';
import { useHistory } from 'react-router';

function StockInfo(props: any) {
    const history = useHistory();
    const user: any = useSelector<RootState>((state) => state.user.user);
    const data: StockOservationId = {
        id: props.id,
    };
    function deleteStockObservation() {
        stockObservation
            .DeleteStockObservation(data)
            .then((res) => {
                if (res.status === STATUS_REQUEST_SUCCESS) {
                    if (res.response_status) {
                        console.log('stock observation deleted!');
                        props.refresh();
                    } else {
                        console.log('errorr stock observation deleted!');
                        for (let i = 0; i < res.error.length; i++) {
                            notify.show(res.error[i]['msg'], 'error', 3000);
                        }
                    }
                } else {
                    console.log('error in deleting stock observation');
                }
            })
            .catch((err) => {
                console.log('error in syncdown' + err);
            });
    }
    return (
        <Fragment>
            <StockInfoBox>
                <TopBar>
                    <Time>
                        {Moment(props.time, 'YYYY-MM-DD').format('DD-MMM-YYYY')}
                    </Time>
                    <Title>{props.title}</Title>
                </TopBar>
                <Content>
                    <div dangerouslySetInnerHTML={{ __html: props.content }} />
                    {/*{props.content}*/}
                </Content>
                {user.role.includes('A') ? (
                    <ButtonRow>
                        <Button
                            variant="contained"
                            style={{ margin: '10px' }}
                            onClick={() =>
                                history.push(
                                    `/edit-stock-observation/${props.id}`
                                )
                            }
                        >
                            Update
                        </Button>
                        <Button
                            variant="contained"
                            style={{ margin: '10px' }}
                            onClick={() => deleteStockObservation()}
                        >
                            Delete
                        </Button>
                    </ButtonRow>
                ) : (
                    ''
                )}
            </StockInfoBox>
        </Fragment>
        // <p>subscribe to access</p>
    );
}
const StockInfoBox = styled.div`
    margin: 30px 15% 30px 15%;
    width: 70%;
    min-height: 80px;
    border: 1px solid grey;
    border-radius: 10px;
    // box-shadow: 5px 5px 0px #C2BFC6;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
`;
const TopBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid grey;
`;
const Time = styled.div`
    align-self: flex-end;
    font-size: 14px;
    color: grey;
    text-align: right;
    margin-right: 10px;
`;
const Title = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-left: 20px;
`;
const Content = styled.div`
    padding: 10px 30px 10px 30px;
`;
const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
`;
export default StockInfo;
