import React, { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Moment from 'moment';
import { ActivateUserForm, UserInfoForm } from '../../services/admin.service';
import * as admin from '../../services/admin.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';

function CalculatorDashboard(props: any) {
    const [seletedDate, setSeletedDate] = useState(new Date());
    const email = props.email;
    const [calculatorUsers, setCalculatorUsers] = useState('');

    const ActivateCalculatorOneMonth = () => {
        let today = new Date();
        const NewDatePlusOne = Moment(today).add(1, 'months');
        const NewDate = Moment(NewDatePlusOne, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
        );
        const data: ActivateUserForm = {
            email: email,
            valid_upto: NewDate,
        };
        admin.ActivateCalculator(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Activated! Till => ' + NewDate);
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    const ActivateCalculatorOneYear = () => {
        let today = new Date();
        const NewDatePlusOne = Moment(today).add(1, 'years');
        const NewDate = Moment(NewDatePlusOne, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
        );
        const data: ActivateUserForm = {
            email: email,
            valid_upto: NewDate,
        };
        admin.ActivateCalculator(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Activated! Till => ' + NewDate);
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const ActivateCalculatorTillDate = () => {
        const NewDate = Moment(seletedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const data: ActivateUserForm = {
            email: email,
            valid_upto: NewDate,
        };
        admin.ActivateCalculator(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Activated! Till => ' + NewDate);
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const handleChange = (date: any) => {
        setSeletedDate(date);
    };

    const handleSelect = (date: any) => {};
    const CalculatorUser = () => {
        const data = {};
        admin.CalculatorUsers(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setCalculatorUsers(res.response);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const DeactivateCalculator = () => {
        const data: UserInfoForm = {
            email: email,
        };
        admin.DeactivateCalculator(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Deactivated!');
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    return (
        <Fragment>
            {/*for calculator activation*/}
            <Title2>For Calculator</Title2>
            <ActivateButtonBoxCalculator>
                <ButtonBoxInfoActivate onClick={ActivateCalculatorOneMonth}>
                    Activate (1 month)
                </ButtonBoxInfoActivate>
                <ButtonBoxInfoActivate onClick={ActivateCalculatorOneYear}>
                    Activate (1 year)
                </ButtonBoxInfoActivate>
                <ButtonBoxInfoActivateTillDate>
                    <ButtonBoxInfoActivate onClick={ActivateCalculatorTillDate}>
                        Activate Till Date =
                    </ButtonBoxInfoActivate>
                    <ButtonBoxInfoActivateDatePicker>
                        <DatePicker
                            selected={seletedDate}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        />
                    </ButtonBoxInfoActivateDatePicker>
                </ButtonBoxInfoActivateTillDate>
            </ActivateButtonBoxCalculator>
            <ButtonBoxInfo onClick={CalculatorUser}>
                Check Calculator Users - {calculatorUsers}
            </ButtonBoxInfo>
            <ButtonBoxInfo onClick={DeactivateCalculator}>
                Deactivate Calculator
            </ButtonBoxInfo>
        </Fragment>
    );
}
const Title2 = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: black;
    margin-top: 15px;
`;
const ButtonBoxInfo = styled.div`
    margin-left: 50px;
    color: white;
    margin-top: 15px;
    align-items: center;
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    cursor: pointer;
    background: #17a2b8;
`;
const ButtonBoxInfoActivateTillDate = styled.div`
    display: flex;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
`;
const ButtonBoxInfoActivateDatePicker = styled.div`
    margin-left: 50px;
    margin-top: 15px;
    height: 50px;
    width: 250px;
    text-align: center;
    padding: 10px;
    font-size: 20px;
`;
const ButtonBoxInfoActivate = styled.div`
    margin-left: 50px;
    margin-top: 15px;
    height: 50px;
    width: 250px;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    background: #00ffcc;
`;
const ActivateButtonBoxCalculator = styled.div`
    display: flex;
    @media (max-width: 575.98px) {
        flex-direction: column;
        // width:90%;
    }
`;
export default CalculatorDashboard;
