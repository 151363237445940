import React from 'react';
import styled from 'styled-components';

function LinkContainer(props: any) {
    return (
        <LinkBox>
            <a href={props.link} target={'_blank'} rel={'noopener noreferrer'}>
                {props.name}
            </a>
        </LinkBox>
    );
}
const LinkBox = styled.div`
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 5px;
    :hover {
        background: #d6dbdf;
        cursor: pointer;
    }
`;
export default LinkContainer;
