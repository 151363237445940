import React, { FormEvent, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import * as auth from '../../services/auth.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { loader } from '../../redux/slices/app.slice';
import Notifications, { notify } from 'react-notify-toast';
import { Img } from '../../components/common/Img';
import { ResetPasswordForm } from '../../services/auth.service';

function ResetPassword(props: any) {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!email) {
            setError("Email can't be empty");
            return;
        }
        setError('');
        setSuccess('');
        setLoading(true);
        const data: ResetPasswordForm = {
            email: email.toLowerCase(),
        };
        auth.ResetPassword(data, loader).then((res) => {
            setLoading(false);
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setSuccess('Email with password link sent!');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        setError(res.error[i]['msg']);
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                setError('Oops! Try again.');
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    return (
        <Modal {...props} size="md" centered>
            <Notifications />
            <NewIdeaBox>
                <Logo>
                    <Img alt="StoxPathshala" src="logo.png" width="100px" />
                </Logo>
                <TitleBar>
                    <Title>Reset Password</Title>
                </TitleBar>
                <CloseIcon onClick={props.onHide}>X</CloseIcon>
                <form onSubmit={onSubmit}>
                    <div>
                        <label style={{ display: 'none' }}>Email</label>
                        <FormInput
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            placeholder="Enter email"
                            value={email}
                        />
                    </div>
                    <ButtonBox>
                        <SubmitButton type="submit">
                            Reset Password
                        </SubmitButton>
                    </ButtonBox>
                </form>

                <SpinLoader>
                    {loading && (
                        <Spinner animation="border" role="status"></Spinner>
                    )}
                </SpinLoader>
                {success && <SuccessMsg>{success}</SuccessMsg>}
                {error && <ErrorMsg>{error}</ErrorMsg>}
            </NewIdeaBox>
        </Modal>
    );
}
const Logo = styled.div`
    position: absolute;
    margin-top: 20px;
`;
const SuccessMsg = styled.div`
    color: #4f8a10;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    background-color: #dff2bf;
`;
const ErrorMsg = styled.div`
    color: #d8000c;
    background-color: #ffd2d2;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
`;
const NewIdeaBox = styled.div`
    border-radius: 20px;
    padding: 0 50px 50px 50px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
const TitleBar = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
`;
const CloseIcon = styled.div`
    position: relative;
    right: 10px;
    float: right;
    margin-top: -25px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
`;
const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SubmitButton = styled.button`
    padding: 5px 25px;
    background-color: gray;
    border: none;
    color: white;
    font-size: 18px;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const SpinLoader = styled.div`
    display: flex;
    justify-content: center;
`;
const FormInput = styled.input`
    width: 100%;
    background: #f9f8fb;
    height: 50px;
    border: 0px solid #f9f8fb;
    font-size: 18px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    outline: none;
    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
export default ResetPassword;
