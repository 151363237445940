import React, { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Moment from 'moment';
import { ActivateUserForm, UserInfoForm } from '../../services/admin.service';
import * as admin from '../../services/admin.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';

function StockDeliveryDashBoard(props: any) {
    const [seletedDate, setSeletedDate] = useState(new Date());
    const [bestDeliveryServiceUsers, setBestDeliveryServiceUsers] =
        useState('');
    const email = props.email;
    const ActivateStockDeliveryServiceOneMonth = () => {
        let today = new Date();
        const NewDatePlusOne = Moment(today).add(1, 'months');
        const NewDate = Moment(NewDatePlusOne, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
        );
        const data: ActivateUserForm = {
            email: email,
            valid_upto: NewDate,
        };
        admin.ActivateStockDeliveryService(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Activated! Till => ' + NewDate);
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    const ActivateStockDeliveryServiceOneYear = () => {
        let today = new Date();
        const NewDatePlusOne = Moment(today).add(1, 'years');
        const NewDate = Moment(NewDatePlusOne, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
        );
        const data: ActivateUserForm = {
            email: email,
            valid_upto: NewDate,
        };
        admin.ActivateStockDeliveryService(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Activated! Till => ' + NewDate);
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const ActivateStockDeliveryServiceTillDate = () => {
        const NewDate = Moment(seletedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const data: ActivateUserForm = {
            email: email,
            valid_upto: NewDate,
        };
        admin.ActivateStockDeliveryService(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Activated! Till => ' + NewDate);
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const StockDeliverServiceUser = () => {
        const data = {};
        admin.StockDeliveryServiceUsers(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setBestDeliveryServiceUsers(res.response);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const DeactivateStockDelivery = () => {
        const data: UserInfoForm = {
            email: email,
        };
        admin.DeactivateStockDeliveryService(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    props.setMsg('Deactivated!');
                    props.onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };
    const handleChange = (date: any) => {
        setSeletedDate(date);
    };

    const handleSelect = (date: any) => {};
    return (
        <Fragment>
            {/*//for stock delivery services*/}
            <Title2>For Stock Delivery Services</Title2>
            <ActivateButtonBoxStockDelivery>
                <ButtonBoxInfoActivate
                    onClick={ActivateStockDeliveryServiceOneMonth}
                >
                    Activate (1 month)
                </ButtonBoxInfoActivate>
                <ButtonBoxInfoActivate
                    onClick={ActivateStockDeliveryServiceOneYear}
                >
                    Activate (1 year)
                </ButtonBoxInfoActivate>
                <ButtonBoxInfoActivateTillDate>
                    <ButtonBoxInfoActivate
                        onClick={ActivateStockDeliveryServiceTillDate}
                    >
                        Activate Till Date =
                    </ButtonBoxInfoActivate>
                    <ButtonBoxInfoActivateDatePicker>
                        <DatePicker
                            selected={seletedDate}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        />
                    </ButtonBoxInfoActivateDatePicker>
                </ButtonBoxInfoActivateTillDate>
            </ActivateButtonBoxStockDelivery>
            <ButtonBoxInfo onClick={StockDeliverServiceUser}>
                Check Stock Delivery Service Users - {bestDeliveryServiceUsers}
            </ButtonBoxInfo>
            <ButtonBoxInfo onClick={DeactivateStockDelivery}>
                Deactivate Stock Delivery
            </ButtonBoxInfo>
        </Fragment>
    );
}
const Title2 = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: black;
    margin-top: 15px;
`;
const ButtonBoxInfo = styled.div`
    margin-left: 50px;
    color: white;
    margin-top: 15px;
    align-items: center;
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    cursor: pointer;
    background: #17a2b8;
`;
const ActivateButtonBoxStockDelivery = styled.div`
    display: flex;
    @media (max-width: 575.98px) {
        flex-direction: column;
        // width:90%;
    }
`;
const ButtonBoxInfoActivateTillDate = styled.div`
    display: flex;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
`;
const ButtonBoxInfoActivate = styled.div`
    margin-left: 50px;
    margin-top: 15px;
    height: 50px;
    width: 250px;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    background: #00ffcc;
`;
const ButtonBoxInfoActivateDatePicker = styled.div`
    margin-left: 50px;
    margin-top: 15px;
    height: 50px;
    width: 250px;
    text-align: center;
    padding: 10px;
    font-size: 20px;
`;
export default StockDeliveryDashBoard;
