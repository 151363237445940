import React, { Fragment } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
function SubcsriptonThankyou() {
    const history = useHistory();
    return (
        <Fragment>
            <Header />
            <ThankyouBlock>
                <ThanyouTitle>Thank you!</ThanyouTitle>
                <DoneOutlineIcon style={{ fontSize: '50px' }} />
                <HomeButton onClick={() => history.push('/home')}>
                    Home Page
                </HomeButton>
            </ThankyouBlock>
            <Footer />
        </Fragment>
    );
}
const ThankyouBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ThanyouTitle = styled.div`
    font-size: 70px;
`;
const HomeButton = styled.div`
    padding: 10px 20px;
    border: 1px solid grey;
    margin: 50px 0;
    cursor: pointer;
`;
export default SubcsriptonThankyou;
