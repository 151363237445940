import React, { Fragment } from 'react';
import LinkContainer from './linkContainer';
import { selectTheme } from '../../constants/theme.constant';
import styled from 'styled-components';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
function Links() {
    return (
        <Fragment>
            <Header />
            <TotalLinks>
                <HighlightTitle>List Of Important Links...</HighlightTitle>
                <div>
                    <LinkContainer
                        link={
                            'https://www.financialexpress.com/market/stock-market/nse-high-delivery/'
                        }
                        name={'1. High Delivery Stocks Analysis'}
                    />
                    <LinkContainer
                        link={
                            'https://www.nseindia.com/companies-listing/corporate-filings-insider-trading'
                        }
                        name={'2. Insider Trading NSE'}
                    />
                    <LinkContainer
                        link={'www.nseindia.com'}
                        name={'3. NSE India'}
                    />
                    <LinkContainer
                        link={'http://www.bseindia.com/'}
                        name={'4. BSE India'}
                    />
                    <LinkContainer
                        link={'http://www.mcxindia.com/'}
                        name={'5. MCX India'}
                    />
                    <LinkContainer
                        link={
                            'https://www.moneycontrol.com/stocks/marketstats/fii_dii_activity/index.php'
                        }
                        name={'6. FII DII DATA'}
                    />
                    <LinkContainer
                        link={
                            'https://www1.nseindia.com/products/content/equities/equities/eq_security.htm'
                        }
                        name={'7. Delivery Volume Analysis'}
                    />
                    <LinkContainer
                        link={
                            'https://www1.nseindia.com/live_market/dynaContent/live_watch/option_chain/optionKeys.jsp?symbolCode=-10006&symbol=NIFTY&symbol=NIFTY&instrument=-&date=-&segmentLink=17&symbolCount=2&segmentLink=17'
                        }
                        name={'8 .Option Chain'}
                    />
                </div>
            </TotalLinks>
            <Footer />
        </Fragment>
    );
}
const getConstants = selectTheme();
const TotalLinks = styled.div`
    display: flex;
    // width:80%;
    flex-direction: column;
    align-items: center;
    @media (max-width: 575.98px) {
        width: 80%;
    }
`;
const HighlightTitle = styled.div`
    font-size: 40px;
    font-family: ${getConstants.font};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
`;
export default Links;
