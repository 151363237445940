import { Request } from './common.service';
import * as config from '../config';

export interface MasterClassSubscriptionForm {
    name: string;
    email: string;
    number: string;
    state: string;
}

export interface NewsLetterSubscriptionForm {
    name: string;
    email: string;
    number: string;
    state: string;
}

export async function MasterClassSubscription(
    form: MasterClassSubscriptionForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlMasterClassSubscription,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function NewsLetterSubscription(
    form: MasterClassSubscriptionForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlNewsLetterSubscription,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
