import React, { Fragment, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import * as admin from '../../services/admin.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import { UserInfoForm } from '../../services/admin.service';
import Moment from 'moment';
import CalculatorDashboard from './calculator';
import StockDeliveryDashBoard from './stockDelivery';
import StockObservationDashBoard from './stockObservation';
import { useHistory } from 'react-router';

function Dashboard() {
    const { control, handleSubmit, register } = useForm();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [status, setStatus] = useState('');
    const [role, setRole] = useState('');
    const [msg, setMsg] = useState('');
    const [activeUsers, setActiveUsers] = useState('');
    const [validpUptoCalculator, setValidUptoCalculator] = useState('');
    const [validpUptoStockDelivery, setValidUptoStockDelivery] = useState('');
    const [validpUptoStockObservation, setValidUptoStockObservation] =
        useState('');

    const history = useHistory();

    const onSubmit = (data: any) => {
        setName('');
        setEmail('');
        setMobile('');
        setStatus('');
        data.email = data.email.toString().toLowerCase();
        admin.UserInfo(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setName(res.response.name);
                    setEmail(res.response.email);
                    setMobile(res.response.mobile);
                    setStatus(res.response.type);
                    setRole(res.response.role);
                    setValidUptoCalculator(
                        Moment(
                            res.response.valid_upto_calculator,
                            'YYYY-MM-DD'
                        ).format('YYYY-MM-DD')
                    );
                    setValidUptoStockDelivery(
                        Moment(
                            res.response.valid_upto_stock_delivery,
                            'YYYY-MM-DD'
                        ).format('YYYY-MM-DD')
                    );
                    setValidUptoStockObservation(
                        Moment(
                            res.response.valid_upto_stock_observation,
                            'YYYY-MM-DD'
                        ).format('YYYY-MM-DD')
                    );
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    const ActiveUsers = () => {
        const data = {};
        admin.ActiveUsers(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setActiveUsers(res.response);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    const updatePassword = () => {
        const data: UserInfoForm = {
            email: email,
        };
        admin.UpdateUserPassword(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setMsg('Password Updated!');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    const DeactivateUser = () => {
        const data: UserInfoForm = {
            email: email,
        };
        admin.DeactivateUser(data, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    setMsg('Deactivated!');
                    onSubmit(data);
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    };

    return (
        <Fragment>
            <Header />
            <ButtonBoxInfo
                onClick={() => history.push('/update-calculator-input')}
            >
                Update Calculator Input
            </ButtonBoxInfo>
            <UserInfo>
                <StyledForm>
                    <Form.Group controlId="email2312">
                        <Controller
                            as={StyledFormControl}
                            name="email"
                            control={control}
                            defaultValue=""
                            placeholder="Enter email"
                            ref={register({ required: true })}
                        />
                    </Form.Group>
                    <ButtonBox onClick={handleSubmit(onSubmit)}>
                        Check
                    </ButtonBox>
                    <NameValue>{msg}</NameValue>
                </StyledForm>
                <InformationBox>
                    <NameBox>
                        <NameTitle>Name -</NameTitle>
                        <NameValue>{name}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>Email -</NameTitle>
                        <NameValue>{email}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>Mobile -</NameTitle>
                        <NameValue>{mobile}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>Status -</NameTitle>
                        <NameValue>{status}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>Role -</NameTitle>
                        <NameValue>{role}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>Calculator Active upto -</NameTitle>
                        <NameValue>{validpUptoCalculator}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>
                            Stock Delivery service Active upto -
                        </NameTitle>
                        <NameValue>{validpUptoStockDelivery}</NameValue>
                    </NameBox>
                    <NameBox>
                        <NameTitle>
                            Stock Observation service Active upto -
                        </NameTitle>
                        <NameValue>{validpUptoStockObservation}</NameValue>
                    </NameBox>
                </InformationBox>
                <ButtonRow>
                    <ButtonBoxInfo onClick={updatePassword}>
                        Update Password
                    </ButtonBoxInfo>
                    <ButtonBoxInfo onClick={DeactivateUser}>
                        Deactivate
                    </ButtonBoxInfo>
                    <ButtonBoxInfo onClick={ActiveUsers}>
                        Check Active Users
                    </ButtonBoxInfo>
                </ButtonRow>
                <NameBox>
                    <NameTitle>Active Users -</NameTitle>
                    <NameValue>{activeUsers}</NameValue>
                </NameBox>

                <CalculatorDashboard
                    email={email}
                    onSubmit={onSubmit}
                    setMsg={setMsg}
                />
                <StockDeliveryDashBoard
                    email={email}
                    onSubmit={onSubmit}
                    setMsg={setMsg}
                />
                <StockObservationDashBoard
                    email={email}
                    onSubmit={onSubmit}
                    setMsg={setMsg}
                />
            </UserInfo>
            <Footer />
        </Fragment>
    );
}
const UserInfo = styled.div`
    margin: 50px 100px 50px 100px;
    @media (max-width: 575.98px) {
        margin: 20px 10px 20px 10px;
    }
`;
const StyledFormControl = styled(Form.Control)`
    background: #f9f8fb;
    height: 50px;
    border: 0px solid #f9f8fb;
    font-size: 18px;
    // border-radius: 10px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    outline: none;

    -webkit-box-shadow: 0 5px 10px #777;
    -moz-box-shadow: 0 5px 10px #777;
    box-shadow: 0 5px 10px #777;
`;
const StyledForm = styled(Form)`
    display: flex;
    @media (max-width: 575.98px) {
        flex-direction: column;
        width: 90%;
    }
    @media (min-width: 1276px) {
        align-items: center;
    }
`;
const ButtonBox = styled.div`
    margin-left: 50px;
    height: 50px;
    width: 150px;
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    cursor: pointer;
    background: green;
`;
const ButtonBoxInfo = styled.div`
    margin-left: 50px;
    color: white;
    margin-top: 15px;
    align-items: center;
    width: 200px;
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    cursor: pointer;
    background: #17a2b8;
`;

const NameBox = styled.div`
    display: flex;
    align-items: center;
`;
const NameTitle = styled.div`
    font-size: 20px;
`;
const NameValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const InformationBox = styled.div``;
const ButtonRow = styled.div`
    display: flex;
    margin-top: 15px;
`;
export default Dashboard;
