import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { selectTheme } from '../../constants/theme.constant';

function HighlightMasterClass() {
    return (
        <Highlights>
            <HighlightTitle>Highlights of Recent Master Class</HighlightTitle>
            <HighLightVideoBox>
                <VideoBox>
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=mTmU0SbJzFk"
                        width="100%"
                        height="100%"
                    />
                </VideoBox>
                <VideoBox>
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=GfOb00WOFlM"
                        width="100%"
                        height="100%"
                    />
                </VideoBox>
            </HighLightVideoBox>
        </Highlights>
    );
}
const getConstants = selectTheme();
const Highlights = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #d6eaf8;
    @media (max-width: 575.98px) {
        padding: 20px;
        margin: 0px;
    }
    @media (min-width: 576px) {
        padding: 40px 0 60px 0;
        margin: 40px 0 40px 0;
    }
`;
const HighlightTitle = styled.div`
    font-size: 50px;
    font-family: ${getConstants.font};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
    @media (max-width: 575.98px) {
        font-size: 30px;
        padding: 0 25px 0 25px;
    }
`;
const HighLightVideoBox = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 576px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
`;
const VideoBox = styled.div`
    @media (max-width: 575.98px) {
        width: 100%;
        height: 350px;
        margin-bottom: 20px;
    }
    @media (min-width: 576px) {
        width: 608px;
        height: 342px;
        margin: 0 10px 0 10px;
    }
`;
export default HighlightMasterClass;
