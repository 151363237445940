import React, { Fragment, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import * as stockObservation from '../../services/stockobservation.service';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { notify } from 'react-notify-toast';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import { StockOservationInput } from '../../services/stockobservation.service';

function AddNewStockObservation() {
    const history = useHistory();
    let value = RichTextEditor.createEmptyValue();
    let [stockObservationInfo, setStockObservationInfo] = useState(value);
    let [title, setTitle] = useState('');
    function handleTitleChange(e: any) {
        setTitle(e.target.value);
    }
    function onChange(c: any) {
        setStockObservationInfo(c);
        value = c;
    }
    function addNewStockObservation() {
        let inputForm: StockOservationInput = {
            uuid: uuidv4(),
            title: title,
            content: stockObservationInfo.toString('html'),
        };
        if (stockObservationInfo.toString('html') === '' || title === '') {
            notify.show('Enter title and description', 'error', 3000);
            return;
        }
        stockObservation.AddNewStockObservation(inputForm).then((res: any) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    history.push('/stockobservation');
                    notify.show(
                        'Stock Observation Info Added',
                        'success',
                        1000
                    );
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            }
        });
    }

    return (
        <Fragment>
            <Header />
            <Title>Add Stocks observation</Title>
            <StyledForm>
                <StyledFormGroup controlId="stockobservationformtitle">
                    <BoldTitle>
                        <StyledFormLabel>Title</StyledFormLabel>
                    </BoldTitle>
                    <StyledFormControl
                        as="input"
                        type="text"
                        placeholder="Title"
                        onChange={handleTitleChange}
                    />
                </StyledFormGroup>
                <StyledFormGroup controlId="stockobservationdescription">
                    <BoldTitle>
                        <StyledFormLabel>About</StyledFormLabel>
                    </BoldTitle>
                    <RichTextEditor
                        value={stockObservationInfo}
                        onChange={onChange}
                    />
                </StyledFormGroup>
                <ButtonBox onClick={() => addNewStockObservation()}>
                    Save
                </ButtonBox>
            </StyledForm>
            <Footer />
        </Fragment>
    );
}
const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const StyledForm = styled(Form)`
    padding: 15px;
    margin: auto;
    width: 80%;
`;
const StyledFormGroup = styled(Form.Group)`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
const StyledFormLabel = styled(Form.Label)`
    font-weight: bold;
`;
const StyledFormControl = styled(Form.Control)`
    background: white;
    height: 40px;
    border: 1px solid #d7dbdd;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 0 20px 0 20px;
    &:focus {
        outline: none;
    }
`;
const BoldTitle = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`;
const ButtonBox = styled.div`
    text-align: center;
    align-self: center;
    cursor: pointer;
    bottom: 50px;
    width: 100px;
    height: 30px;
    border-radius: 8px;
    color: white;
    background: grey;
    margin: 20px 0 30px 0;
    padding: 5px 0 0 0;
`;
export default AddNewStockObservation;
