import { Request } from './common.service';
import * as config from '../config';

export interface UserInfoForm {
    email: string;
}
export interface ActivateUserForm {
    email: string;
    valid_upto: string;
}
export async function UserInfo(
    form: UserInfoForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminUserInfo,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function ActiveUsers(
    form: {},
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminActiveUsers,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function CalculatorUsers(
    form: {},
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminCalculatorUsers,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function StockDeliveryServiceUsers(
    form: {},
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminStockDeliveryServiceUsers,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function StockObservationServiceUsers(
    form: {},
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminStockObservationServiceUsers,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function UpdateUserPassword(
    form: UserInfoForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminUpdateUserPassword,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function DeactivateUser(
    form: UserInfoForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminDeactivateUser,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function DeactivateCalculator(
    form: UserInfoForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminDeactivateCalculator,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function DeactivateStockDeliveryService(
    form: UserInfoForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminDeactivateStockDelivery,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
export async function DeactivateStockObservationService(
    form: UserInfoForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminDeactivateStockObservation,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function ActivateCalculator(
    form: ActivateUserForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminActivateCalculator,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function ActivateStockDeliveryService(
    form: ActivateUserForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminActivateStockDeliveryService,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}

export async function ActivateStockObservationService(
    form: ActivateUserForm,
    loader?: Function,
    toast?: Function
) {
    return await Request({
        url: config.urlAdminActivateStockObservationService,
        method: 'POST',
        form: form,
        loader: loader,
        toast: toast,
    });
}
