import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BuyChartDonutVictory from './buyChartDonutVictory';
import SellChartDonutVictory from './sellChartDonutVictory';
import * as calculator from '../../services/calculator.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { useForm } from 'react-hook-form';
import Notification, { notify } from 'react-notify-toast';
import {Input} from "../../services/calculator.service";
import Select from 'react-select';
import { Spinner } from 'react-bootstrap';
import styles from './GannChart.module.css';
import querystring from 'querystring';
import { Img } from "../../components/common/Img";

function GannChartIndUsa(props: any) {
    const [buy, setBuy] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [sell, setSell] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [matrix, setMatrix] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [equityData, setEquityData] = useState<Array<{label:string, value:string}>|[]>([]);
    const [selectedEquity, setSelectedEquity] = useState<{label:string, value:string} | null>(null);
    const [value, setValue] = useState('0');
    const [stockInputValue, setStockInputValue] = useState({value:''});

    const [nasdaqData, setNasdaqData] = useState([]);
    const [selectedNasdaq, setSelectedNasdaq] = useState<{id: string, value: string}|null>(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputForCalculate: Input = {
        input_value: '0',
    };
    const [query, setQuery] = useState('');
    const timeout: any = useRef();
    const [selectedCountry, setSelectedCountry] = useState('India');
    const countryTypes = ['India', 'USA'];

    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [selectedFromSuggestions, setSelectedFromSuggestions] = useState('');
    const [selectedType, setSelectedType] = useState('equity');
    const selectedTypes: Array<{ [key: string]: string }> = [
        { equity: 'Equity' },
        { futureAndOptions: 'Future and Options' },
    ];
    const { handleSubmit } = useForm();

    const handleCountryChange = (name:string)=>{
        setSelectedCountry(name)
    }
    const handleEquityChange = (selectedOption: any) => {
        setSelectedEquity(selectedOption);
    };
    const handleNasdaqChange = (suggestion: {id: string, data: string, created: string}) => {
        setSelectedNasdaq({id: suggestion?.id??'', value: suggestion?.data??'0' })
        setShowSuggestions(false);
    };
    const handleNasdaqFocus =(e:any) =>{
        e.stopPropagation();
        setShowSuggestions(true);
        resetSelection();
    }

    const resetSelection = () => {
        setError('');
        setSelectedEquity(null);
        setStockInputValue({value:''})
        setValue('0');
        setBuy([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        setSell([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        setMatrix([0, 0, 0, 0, 0, 0, 0]);
        setSelectedNasdaq(null);
        setQuery('')
    };
    const onKeyDown = (e:any) => {
        e.preventDefault();
        // const { activeSuggestion, filteredSuggestions } = this.state;
        console.log(e.keyCode)
        // User pressed the enter key
        if (e.keyCode === 13) {
            setShowSuggestions(false);
            // @ts-ignore
            setSelectedFromSuggestions(nasdaqData[activeSuggestion]?.id ??'');
            // @ts-ignore
            setValue(nasdaqData[activeSuggestion]?.data??"");
            e.preventDefault();

            // this.setState({
            //     activeSuggestion: 0,
            //     showSuggestions: false,
            //     userInput: filteredSuggestions[activeSuggestion]
            // });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion => activeSuggestion - 1)
            // this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === nasdaqData.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion => activeSuggestion + 1)
            // this.setState({ activeSuggestion: activeSuggestion + 1 });
        }

    };

    const onSubmit = () => {
        setLoading(true);
        setError('')
        if (value === '0') {
            setLoading(false);
            setBuy([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            setSell([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            setMatrix([0, 0, 0, 0, 0, 0, 0]);
            setError('Please select a value');
            return;
        }
        inputForCalculate.input_value = value.toString();
        calculator.Calculate(inputForCalculate, loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                setLoading(false);
                if (res.response_status) {
                    setBuy(res.response['Buy']);
                    setSell(res.response['Sell']);
                    setMatrix(res.response['SwingMatrix']);
                } else {
                    notify.show('Enter stock name or value', 'error', 3000);
                }
            } else {
                setLoading(false);
                notify.show('Error, try logging in again', 'error', 3000);
            }
        });
    };
    const getSuggestionsFromServer = useCallback(() => {
        setLoading(true);
        const searchParams = querystring.stringify({
            query: query,
            type: selectedType,
            country: selectedCountry,
        });
        calculator
            .GetCalcutorInputMultipleDataSet(searchParams, loader)
            .then((res) => {
                setLoading(false);
                if (res.status === STATUS_REQUEST_SUCCESS) {
                    if (res.response_status) {
                        setShowSuggestions(true);
                        setNasdaqData(res.response);
                    } else {
                        setShowSuggestions(false);
                        setError('No Stock Found')
                        // notify.show('Enter stock name or value', 'error', 3000);
                    }
                } else {
                    setError('Please select valid stock')
                    setLoading(false);
                    // notify.show('Error, try logging in again', 'error', 3000);
                }
            });
    }, [query]);

    useEffect(()=>{
        setLoading(true);
        calculator.GetCalcutorEquityData({}, loader).then((res) => {
            setLoading(false);
            if(res.status === STATUS_REQUEST_SUCCESS){
                if(res.response_status) {
                    setEquityData(res.response.data)
                }
                else{
                    notify.show("Enter stock name or value", "error", 3000);
                }
            }
            else{
                setLoading(false);
                notify.show("Error, try logging in again", "error", 3000);
            }
        })
    },[])

    useEffect(() => {
        if (query) {
            if (timeout) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(getSuggestionsFromServer, 500);
        }
    }, [query]);

    useEffect(()=>{
        if(selectedEquity?.value){
            setValue(selectedEquity.value)
        }else if (stockInputValue?.value){
            setValue(stockInputValue.value)
        }else if (selectedNasdaq?.value){
            setValue(selectedNasdaq.value.slice(1,).replace(',','')??0)
        }
    },[selectedEquity, stockInputValue, selectedNasdaq]);

    useEffect(()=>{
        resetSelection();
    },[selectedCountry])


    const handleStockValueChange = (e:any) => {
        setQuery(e.target.value);
    }
    return (
        <div className={styles['container']} onClick={()=> setShowSuggestions(false)}>
            <Header />
            <Title>Trend Trading Calculator</Title>
            <SubTitle>"Trend is your only Friend"</SubTitle>
            <StyledForm>
                <div className={styles['country-radio-div']}>
                    {countryTypes.map((item) => {
                        return (
                          <div className={[styles['flag-button'],
                              selectedCountry === item
                                ? styles['active-flag']
                                : '',
                          ].join(' ')}
                               onClick={() => handleCountryChange(item)}>
                              <Img className={styles['flag-icon']} alt={item} src={'countryIcons/'+item.toLowerCase()+'.png'} height='40px' width='40px'/>
                            <button
                                type="button"
                                className={[
                                    styles['button'],
                                    selectedCountry === item
                                        ? styles['active']
                                        : '',
                                ].join(' ')}
                            >
                                {item}
                            </button>
                          </div>
                        );
                    })}
                </div>
                <div className={styles['stock-type']}>
                    <label className={styles['radio-trading-type']}>
                        <input
                          type="radio"
                          name={selectedCountry === 'India'? 'Equity': 'Nasdaq'}
                          checked={true}
                        />
                        <span>{selectedCountry === 'India'? 'Equity': 'Nasdaq'}</span>
                    </label>
                </div>
                {
                    selectedCountry === "USA" ? <div><input
                      type="text"
                      className={styles['custom-input']}
                      placeholder="Enter Stock Name"
                      onChange={handleStockValueChange}
                      onClick={e=> e.stopPropagation()}
                      // onKeyDown={onKeyDown}
                      onFocus={handleNasdaqFocus}
                      value={selectedNasdaq ? selectedNasdaq.id : query}
                    /></div> : null
                }
                {showSuggestions && nasdaqData.length>0 && (
                  <ul className={styles['suggestions']}>
                      {nasdaqData && nasdaqData.map((suggestion) => {
                          return (
                            // @ts-ignore
                            <li key={suggestion} onClick={() => handleNasdaqChange(suggestion)}> {suggestion.id}
                            </li>
                          );
                      })}
                  </ul>
                )}
                {
                    selectedCountry === "India" ? <Select
                      options={equityData}
                      value={selectedEquity}
                      onChange={handleEquityChange}
                      onFocus={resetSelection}
                    /> : null
                }

                or
                <BottomValueRow>
                    <input
                        type="text"
                        name="text_input"
                        value={stockInputValue.value}
                        placeholder="Enter stock value"
                        onFocus={resetSelection}
                        onChange={(e: any) => {
                            setStockInputValue({value:e.target.value});
                        }}
                        className={styles['query-input']}
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                    >
                        Go
                    </Button>
                    <SpinLoader>
                        {loading && (
                            <Spinner animation="border" role="status"></Spinner>
                        )}
                    </SpinLoader>
                </BottomValueRow>
                {selectedCountry === 'USA'? <strong className={styles['price-info']}>Prices are in USD.</strong> :null}

                <InformationAboutStock>
                    *For Future and Options/ International Shares/ Commodities/
                    Forex/ Cryptocurrencies, "Enter Last Price"
                </InformationAboutStock>
                <small style={{ color: 'red' }}>{error && error}</small>
                {/*</Form>*/}
            </StyledForm>
            <Container>
                <Notification />
                <DataBox>
                    <CenterContaiener>
                        <TitleLeft>Intraday Target</TitleLeft>
                        <ChartBox>
                            <DonutChartContainer>
                                <BuyChartDonutVictory s={buy} />
                                <BuyBox>
                                    <BuyTitle>Buy above -&nbsp;</BuyTitle>
                                    <BuyValue>{buy[0]}</BuyValue>
                                </BuyBox>
                                Stop Loss
                                <SellBox>
                                    <SellTitle>Sell below -&nbsp;</SellTitle>
                                    <SellValue>{sell[0]}</SellValue>
                                </SellBox>
                            </DonutChartContainer>
                            <DonutChartContainer>
                                <SellChartDonutVictory s={sell} />
                                <SellBox>
                                    <SellTitle>Sell below -&nbsp;</SellTitle>
                                    <SellValue>{sell[0]}</SellValue>
                                </SellBox>
                                Stop Loss
                                <BuyBox>
                                    <BuyTitle>Buy above -&nbsp;</BuyTitle>
                                    <BuyValue>{buy[0]}</BuyValue>
                                </BuyBox>
                            </DonutChartContainer>
                        </ChartBox>
                    </CenterContaiener>
                </DataBox>
                <PostionalBox>
                    <TitleRight>Position/Swing Target</TitleRight>
                    <PositionalContainers>
                        <ResistanceBoxContainer>
                            <ResistanceBox>
                                <ResistanceTitle>Target 3 -</ResistanceTitle>
                                <ResistanceValue>{matrix[0]}</ResistanceValue>
                            </ResistanceBox>
                            <ResistanceBox>
                                <ResistanceTitle>Target 2 -</ResistanceTitle>
                                <ResistanceValue>{matrix[1]}</ResistanceValue>
                            </ResistanceBox>
                            <ResistanceBox>
                                <ResistanceTitle>Target 1 -</ResistanceTitle>
                                <ResistanceValue>{matrix[2]}</ResistanceValue>
                            </ResistanceBox>
                        </ResistanceBoxContainer>
                        <PivotBoxContainer>
                            <PivotBox>
                                <PivotTitle>Breakout/Breakdown Level</PivotTitle>
                                <PivotValue>{matrix[3]}</PivotValue>
                            </PivotBox>
                        </PivotBoxContainer>
                        <SupportBoxContainer>
                            <SupportBox>
                                <SupportTitle>Target 1 -</SupportTitle>
                                <SupportValue>{matrix[4]}</SupportValue>
                            </SupportBox>
                            <SupportBox>
                                <SupportTitle>Target 2 -</SupportTitle>
                                <SupportValue>{matrix[5]}</SupportValue>
                            </SupportBox>
                            <SupportBox>
                                <SupportTitle>Target 3 -</SupportTitle>
                                <SupportValue>{matrix[6]}</SupportValue>
                            </SupportBox>
                        </SupportBoxContainer>
                    </PositionalContainers>
                </PostionalBox>
            </Container>
            <CommentBox>
                ** If Closing above T3 & T6, you can carry your positions
                overnight with SL T2 & T5 respectively **
            </CommentBox>
            <Footer />
        </div>
    );
}
const StyledForm = styled(Form)`
    margin: 20px;
    width: 40%;
    z-index: 2;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;

const BottomValueRow = styled(Form)`
    display: flex;
    width: 100%;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const InformationAboutStock = styled.div`
    font-size: 12px;
    color: grey;
`;
const ChartBox = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;

const SellBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 200px;
    border: 1px solid red;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const SellTitle = styled.div``;
const SellValue = styled.div``;
const BuyBox = styled.div`
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    width: 200px;
    border: 1px solid green;
    border-radius: 8px;
    font-weight: bold;
`;
const BuyTitle = styled.div``;
const CommentBox = styled.div`
    font-size: 16px;
    margin-bottom: 100px;
    margin-left: 80px;
    color: #808b96;
    @media (max-width: 575.98px) {
        margin-left: 30px;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const BuyValue = styled.div`
    display: flex;
`;
const DonutChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 10px;
`;
const TitleLeft = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    background: #042f66;
    width: 40%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    @media (max-width: 575.98px) {
        width: 80%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const TitleRight = styled.div`
    font-size: 23px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 20px;
    background: #042f66;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
`;
const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const SubTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #042f66;
    text-align: center;
`;
const DataBox = styled.div`
    width: 75%;
    // align-items:center;
    display: flex;
    flex-direction: column;
    @media (max-width: 575.98px) {
        width: 100%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 100%;
    }
    @media (min-width: 768px) {
        //margin: 20px 0 0 0;
        padding: 20px 0 0 50px;
    }
`;
const Container = styled.div`
    display: flex;
    margin-bottom: 10px;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        flex-direction: column;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const CenterContaiener = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const PostionalBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items:center;
    //padding: 50px 0 0 50px;
    width: 25%;
    @media (max-width: 575.98px) {
        width: 100%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 100%;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
    }
    @media (min-width: 1276px) {
    }
`;
const PositionalContainers = styled.div`
    width: 100%;
  display: flex;
  flex-direction: column;
    align-items: center;
    @media (min-width: 576px) and (max-width: 767.98px) {
        flex-direction: row;
        justify-content: center;
    }
`;
const ResistanceBox = styled.div`
    display: flex;
    align-items: center;
`;
const ResistanceTitle = styled.div`
    font-size: 20px;
`;
const ResistanceValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const PivotBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const PivotTitle = styled.div`
    font-size: 20px;
`;
const PivotValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const ResistanceBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #abebc6;
    padding: 20px;
    border-radius: 8px;
    margin: 5px 5px;
    width: 90%;
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 30%;
    }
`;
const PivotBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #d6dbdf;
    padding: 20px;
    margin:5px 5px;
    border-radius: 8px;
    width: 90%;
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 30%;
    }
`;
const SupportBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #f5b7b1;
    padding: 20px;
    margin: 5px 5px;
    border-radius: 8px;
    width: 90%;
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 30%;
    }
`;
const SupportBox = styled.div`
    display: flex;
    align-items: center;
`;
const SupportTitle = styled.div`
    font-size: 20px;
`;
const SupportValue = styled.div`
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
`;
const SpinLoader = styled.div`
    width: 50px;
`;
export default GannChartIndUsa;
